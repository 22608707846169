import { useGetUserRoles } from 'api/users-hooks';
import { useAuth } from 'common/hooks/useAuth';
import { Supplier } from 'common/types/supplier';
import { getSupplierForOwner } from 'common/utils/supplier';
import { createContext, ReactElement, useMemo, useState } from 'react';

export type SupplierContextType = {
    supplier: Supplier | null;
    suppliersList: Supplier[];
    setSupplierIdx: (index: number) => void;
};

export const SupplierContext = createContext<SupplierContextType | null>(null);

export const SupplierProvider = ({ children }: { children: ReactElement }) => {
    const { user } = useAuth();

    const { data: userRoles } = useGetUserRoles(user?._id!);
    const [supplierIdx, setSupplierIdx] = useState(0);

    const supplier = useMemo(() => getSupplierForOwner(userRoles, supplierIdx), [userRoles, supplierIdx]);
    const suppliersList = useMemo(() => {
        const filteredTeamMemberSuppliers = userRoles?.teamMember.filter(
            (item) => !userRoles?.owner.some((supplier) => supplier._id === item._id)
        );

        return userRoles?.owner.concat(filteredTeamMemberSuppliers ?? []) ?? [];
    }, [userRoles]);

    return (
        <SupplierContext.Provider value={{ supplier, suppliersList, setSupplierIdx }}>
            {children}
        </SupplierContext.Provider>
    );
};
