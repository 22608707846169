import { useParams } from 'react-router-dom';
import { displayDate, displayTime } from 'common/utils/date';
import { ListCardText, ListCardTitle } from 'components/Text';
import { Section } from 'components/layout';
import { Link, Grid, Paper, Button, Stack, useTheme, Divider, Box } from '@mui/material';
import { MediumActionButton } from 'components/Buttons';
import { useLocations } from 'common/hooks/useLocations';
import { getGoogleMapsLink } from 'common/utils/location';
import { useUpdateEventStatus, useGetEvent } from 'api/events-hooks';
import { useGetSupplier } from 'api/suppliers-hooks';
import { useTranslation } from 'react-i18next';
import { EventStatus, EventType } from 'common/types/calendar';
import { ConfirmationStatusIcon, IconWithText } from 'components/Icons';
import {
    IconBookmark,
    IconCalendar,
    IconClock,
    IconMoodConfuzed,
    IconMoodCrazyHappy,
    IconMoodEmpty,
    IconMoodSmile,
    IconMoodSad,
} from '@tabler/icons-react';
import Map from 'components/Map';
import { Image } from 'components/Image';
import { Supplier } from 'common/types/supplier';

interface ArrivalStatusProps {
    event: EventType;
}

interface EventInfoProps {
    supplier: Supplier;
    event: EventType;
}

const ArrivalStatus = (props: ArrivalStatusProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const updateEventStatus = useUpdateEventStatus();

    const cancel = () => {
        updateEventStatus.mutate({ eventId: props.event._id ?? '', eventStatus: EventStatus.Cancelled });
    };

    const confirm = () => {
        updateEventStatus.mutate({ eventId: props.event._id ?? '', eventStatus: EventStatus.Verified });
    };

    const isDisabled = (event: EventType) => {
        return event.status === EventStatus.Tentative || event.status === EventStatus.Cancelled;
    };

    const getIcon = (event: EventType) => {
        switch (event.status) {
            case EventStatus.Scheduled:
                return IconMoodSmile;

            case EventStatus.Verified:
                return IconMoodCrazyHappy;

            case EventStatus.Cancelled:
                return IconMoodSad;

            case EventStatus.Tentative:
                return IconMoodEmpty;

            default:
                return IconMoodConfuzed;
        }
    };

    return (
        <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={2} justifyContent="start">
                <Grid item xs={12}>
                    <ListCardTitle text={t('views.booking.confirmation.arrival.title')} />
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            height: 100,
                            width: 100,
                            color: theme.palette.primary.main,
                            border: 1,
                            borderRadius: '4px',
                            borderColor: theme.palette.text.disabled,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ConfirmationStatusIcon icon={getIcon(props.event)} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Stack rowGap={2}>
                        <Button
                            variant="outlined"
                            onClick={confirm}
                            sx={{ width: 120 }}
                            disabled={isDisabled(props.event)}
                        >
                            {t('views.booking.confirmation.arrival.confirm')}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={cancel}
                            sx={{ width: 120 }}
                            disabled={isDisabled(props.event)}
                        >
                            {t('views.booking.confirmation.arrival.cancel')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
};

const EventInfo = (props: EventInfoProps) => {
    const { t } = useTranslation();
    const { getLocation } = useLocations();

    return (
        <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={2} justifyContent="space-around">
                <Grid item xs={12}>
                    <Grid container justifyContent="start" alignItems="center" spacing={2}>
                        <Grid item>
                            <Image
                                sx={{
                                    height: 80,
                                    width: 80,
                                    borderRadius: '4px',
                                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                }}
                                src={props.supplier.images.profile}
                                alt={props.supplier.info.name}
                            />
                        </Grid>
                        <Grid item xs={7} md={6}>
                            <ListCardText
                                title={props.supplier.info.name}
                                caption={t(`views.booking.confirmation.status.${props.event.status}`, {
                                    supplier: props.supplier.info.name,
                                })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    <IconWithText
                        icon={IconBookmark}
                        text={t('components.schedule.details.service', {
                            serviceLabel: props.event.serviceLabel,
                            teamMember: props.event.teamMember.name,
                        })}
                    />
                </Grid>
                <Grid item xs={11}>
                    <IconWithText icon={IconCalendar} text={displayDate(props.event.start)} />
                </Grid>
                <Grid item xs={11}>
                    <IconWithText
                        icon={IconClock}
                        text={t('components.schedule.details.interval', {
                            start: displayTime(props.event.start),
                            end: displayTime(props.event.end),
                        })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Map location={props.supplier.info.location} readonly={true} onLocationChange={() => {}} />
                </Grid>
                <Grid item xs={12}>
                    <Link
                        href={getGoogleMapsLink(getLocation(), props.supplier)}
                        color="inherit"
                        underline="none"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <MediumActionButton label={t('views.booking.confirmation.directions')} onClick={() => {}} />
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
};

export const Confirmation = () => {
    const { eventId } = useParams();
    const { data: event } = useGetEvent(eventId ?? '');
    const { data: supplier } = useGetSupplier(event ? event.supplier._id! : '');

    if (!event || !supplier) {
        return <></>;
    }

    return (
        <Section>
            <Grid container spacing={2} justifyContent="start">
                <Grid item xs={12} md={4}>
                    <EventInfo supplier={supplier} event={event} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <ArrivalStatus event={event} />
                </Grid>
            </Grid>
        </Section>
    );
};
