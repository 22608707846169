import { RouteObject } from 'react-router-dom';
import { MainLayout } from 'components/layout';
import { Home } from 'views/home';
import { SupplierHome } from 'views/supplier/home';
import { Contact } from 'views/contact';
import { Search } from 'views/search';
import { lazy } from 'react';

const LandingPage = lazy(() => import('views/landing'));

export const BaseRoutes: RouteObject = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <Home />,
        },
        {
            path: '*',
            element: <Home />,
        },
        {
            path: 'landing',
            element: <LandingPage />,
        },
        {
            path: 'search',
            element: <Search />,
        },
        {
            path: 'contact',
            element: <Contact />,
        },
        {
            path: 'suppliers/:supplierId',
            element: <SupplierHome />,
        },
    ],
};
