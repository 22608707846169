import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from 'serviceWorker';
import App from 'App';

const root = createRoot(document.getElementById('app')!);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
