import { Box, Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

export interface TransitionProps {
    children: ReactElement;
    in: boolean;
}

export interface LoadingProps {
    children: ReactElement;
    loadingElement: ReactElement;
    loading: boolean;
}

export const FadeIn = (props: TransitionProps) => {
    return (
        <Fade in={props.in} style={{ transformOrigin: '0 0 0' }} {...(props.in ? { timeout: 1000 } : {})}>
            <Box>{props.children}</Box>
        </Fade>
    );
};

export const Loading = (props: LoadingProps) => {
    const minLoadingTimeout = 500;
    const [minLoadingExpired, setMinLoadingExpired] = useState<boolean>(true);

    useEffect(() => {
        if (props.loading) {
            setMinLoadingExpired(false);
            setTimeout(() => {
                setMinLoadingExpired(true);
            }, minLoadingTimeout);
        }
    }, [props.loading]);

    if (!minLoadingExpired || props.loading) {
        return props.loadingElement;
    }

    return props.children;
};
