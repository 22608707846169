import { Typography, IconButton, Stack, useTheme, Tooltip } from '@mui/material';
import { IconChevronLeft, IconChevronRight, TablerIconsProps } from '@tabler/icons-react';
import { containsRtlChars } from 'common/utils/locale';
import { createElement, ReactElement } from 'react';

export type TablerIcon = (props: TablerIconsProps) => JSX.Element;

export interface IconProps {
    icon: TablerIcon;
}

export interface ActionableIconProps extends IconProps {
    onClick: () => void;
    color: 'primary' | 'secondary';
}

export interface HoverIconProps extends IconProps {
    link: string;
    color: string;
    hide: boolean;
}

export interface IconWithTextProps extends IconProps {
    text?: string;
}

export interface IconWithElementProps extends IconProps {
    element: ReactElement;
}

export interface IconWithTooltipProps extends IconProps {
    onClick: () => void;
    tooltip: string;
    hoverColor: string;
}

export const IconWithText = (props: IconWithTextProps) => {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" gap={2} color={theme.palette.text.primary}>
            <Icon icon={props.icon} />
            <Typography
                variant="h5"
                flex={1}
                style={{
                    whiteSpace: 'pre-wrap',
                    direction: containsRtlChars(props.text ?? '') ? 'rtl' : 'ltr',
                    textAlign: theme.direction === 'ltr' ? 'left' : 'right',
                    lineBreak: props.text && props.text.indexOf(' ') > 0 ? 'auto' : 'anywhere',
                }}
            >
                {props.text}
            </Typography>
        </Stack>
    );
};

export const IconWithElement = (props: IconWithElementProps) => {
    return (
        <Stack direction="row" alignItems="center" gap={2}>
            <Icon icon={props.icon} />
            {props.element}
        </Stack>
    );
};

export const ActionableIcon = (props: ActionableIconProps) => {
    return (
        <IconButton onClick={props.onClick} color={props.color} sx={{ padding: 0 }}>
            <Icon icon={props.icon} />
        </IconButton>
    );
};

export const IconNext = () => {
    const theme = useTheme();
    return theme.direction === 'rtl' ? (
        <IconChevronLeft stroke={1.2} style={{ margin: 0 }} />
    ) : (
        <IconChevronRight stroke={1.2} style={{ margin: 0 }} />
    );
};

export const IconPrev = () => {
    const theme = useTheme();
    return theme.direction === 'rtl' ? (
        <IconChevronRight stroke={1.2} style={{ margin: 0 }} />
    ) : (
        <IconChevronLeft stroke={1.2} style={{ margin: 0 }} />
    );
};

export const Icon = (props: IconProps): ReactElement => {
    return createElement(props.icon, { stroke: 1.2 });
};

export const ConfirmationStatusIcon = (props: IconProps): ReactElement => {
    return createElement(props.icon, { stroke: 0.9, size: 40 });
};

export const HoverColoredIcon = (props: HoverIconProps) => {
    if (props.hide) {
        return <></>;
    }

    return (
        <IconButton href={props.link} target="_blank" sx={{ ':hover': { color: props.color } }}>
            <Icon icon={props.icon} />
        </IconButton>
    );
};

export const IconWithTooltip = (props: IconWithTooltipProps) => {
    return (
        <Tooltip title={props.tooltip}>
            <IconButton color="inherit" onClick={props.onClick} sx={{ ':hover': { color: props.hoverColor } }}>
                <Icon icon={props.icon} />
            </IconButton>
        </Tooltip>
    );
};
