import { useNavigate, useParams } from 'react-router-dom';
import { Info } from './Info';
import { Grid } from '@mui/material';
import { useGetSupplier } from 'api/suppliers-hooks';
import { Section } from 'components/layout';
import { WebSchedule, MobileSchedule } from './Schedule';
import { useLayout } from 'common/hooks/useLayout';

export const SupplierHome = () => {
    const navigate = useNavigate();

    const { openDialog } = useLayout();
    const { supplierId } = useParams();

    const { data: supplier, isError } = useGetSupplier(supplierId ?? '');

    if (isError || !supplierId) {
        navigate('/');
    }

    if (!supplier) {
        return <></>;
    }

    return (
        <Section>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={4}>
                    <Info
                        supplier={supplier}
                        onSchedule={() =>
                            openDialog({
                                children: <MobileSchedule supplier={supplier} />,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <WebSchedule supplier={supplier} />
                </Grid>
            </Grid>
        </Section>
    );
};
