import { ReactElement } from 'react';
import { Supplier } from './supplier';
import * as Yup from 'yup';
import { isUri } from 'valid-url';

export interface UserProfile {
    _id?: string;
    picture?: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone?: string;
}

export const UserProfileSchema = Yup.object<Record<keyof UserProfile, Yup.AnySchema>>({
    _id: Yup.string().optional().length(24),
    picture: Yup.string()
        .required()
        .test('valid-uri', (value) => {
            return isUri(value ?? '') !== undefined;
        }),
    firstName: Yup.string().required().max(15),
    lastName: Yup.string().required().max(15),
    fullName: Yup.string().required().max(30),
    email: Yup.string().required().email(),
    phone: Yup.string().required().min(16).max(17),
});

export interface UserCredentials {
    email: string;
    password: string;
}

export const UserCredentialsSchema = Yup.object<Record<keyof UserCredentials, Yup.AnySchema>>({
    email: Yup.string().required().email(),
    password: Yup.string().required().min(6).max(15),
});

export interface UserRoles {
    _id: string;
    owner: Supplier[];
    teamMember: Supplier[];
}

export interface AuthResponse {
    user: UserProfile;
    token: string;
}

export interface AuthState {
    isLoggedIn: boolean;
    user: UserProfile | undefined;
}

export type LoginCallbacks = {
    onSuccess: (user: UserProfile) => void;
    onError: () => void;
};

export type LogoutCallbacks = {
    onSuccess: () => void;
};

export type AuthContextType = {
    isLoggedIn: boolean;
    user: UserProfile | null;
    login: (email: string, password: string, authCallbacks: LoginCallbacks) => void;
    register: (user: UserProfile, password: string, authCallbacks: LoginCallbacks) => void;
    loginWithGoogle: (authCallbacks: LoginCallbacks) => void;
    logout: (authCallbacks: LogoutCallbacks) => void;
    updateUserDetails: (user: UserProfile) => void;
};

export type GuestGuardProps = {
    children: ReactElement | null;
};

export type AuthGuardProps = {
    children: ReactElement | null;
};
