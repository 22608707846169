import { Grid, CardActions, Button } from '@mui/material';
import { IconNext } from 'components/Icons';
import { ReactElement } from 'react';

export interface ListCardProps {
    element: ReactElement;
    onClick: () => void;
}

export default function ListCard(props: ListCardProps) {
    return (
        <Button variant="outlined" fullWidth onClick={props.onClick} sx={{ p: 2 }}>
            <Grid container alignItems="center">
                <Grid item xs={9} sx={{ textAlign: 'start' }}>
                    {props.element}
                </Grid>
                <Grid item xs={3}>
                    <CardActions sx={{ justifyContent: 'end' }}>{IconNext()}</CardActions>
                </Grid>
            </Grid>
        </Button>
    );
}
