import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface FooterLinkButtonProps {
    label: string;
    link: string;
}

export const FooterLink = (props: FooterLinkButtonProps) => {
    const navigate = useNavigate();

    return (
        <Link component="button" variant="body2" color="inherit" underline="none" onClick={() => navigate(props.link)}>
            {props.label}
        </Link>
    );
};
