import { useNotifications } from 'common/hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAccurateLocation, getLocationByIp } from './location';

export const useGetLocationByIp = () => {
    return useQuery('location', () => getLocationByIp(), {
        staleTime: Infinity,
    });
};

export const useGetAccurateLocation = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(getAccurateLocation, {
        onError: () => {
            notify(t('notifications.location.update.error'));
        },
        onSuccess: (location) => {
            queryClient.setQueryData('location', location);

            notify(t('notifications.location.update.success'));
        },
    });
};
