import { useMutation, useQuery } from 'react-query';
import { getAuthToken, getGoogleLoginUrl, GoogleLoginOptions, login, register, revokeAuthToken } from './auth';

const GOOGLE_LOGIN_URL = (opts: GoogleLoginOptions) => ['googleLoginUrl', ...Object.values(opts)];

export const useGetGoogleLoginUrl = (opts: GoogleLoginOptions) => {
    return useQuery(GOOGLE_LOGIN_URL(opts), () => getGoogleLoginUrl(opts), {
        enabled: !!opts.state,
    });
};

export const useGetAuthToken = () => {
    return useMutation(getAuthToken);
};

export const useLocalLogin = () => {
    return useMutation(login);
};

export const useLocalRegister = () => {
    return useMutation(register);
};

export const useRevokeAuthToken = () => {
    return useMutation(revokeAuthToken);
};
