import { Stack, Typography } from '@mui/material';

export interface TextProps {
    text: string;
}

export interface ListCardTextProps {
    title: string;
    caption: string;
}

export const Title = (props: TextProps) => {
    return (
        <Typography variant="h4" color="primary" sx={{ mb: 1 }}>
            {props.text}
        </Typography>
    );
};

export const FooterTitle = (props: TextProps) => {
    return (
        <Typography variant="h5" color="#262626" sx={{ mb: 1 }}>
            {props.text}
        </Typography>
    );
};

export const FooterCaption = (props: TextProps) => {
    return (
        <Typography variant="body2" align="right" style={{ direction: 'ltr' }}>
            {props.text}
        </Typography>
    );
};

export const TextHeader = (props: TextProps) => {
    return <Typography variant="h4">{props.text}</Typography>;
};

export const TextCaption = (props: TextProps) => {
    return <Typography variant="h6">{props.text}</Typography>;
};

export const TextMessage = (props: TextProps) => {
    return <Typography variant="body1">{props.text}</Typography>;
};

export const ContactCardSubHeader = (props: TextProps) => {
    return (
        <Typography variant="body2" color="#00000099" align="left" style={{ direction: 'ltr' }}>
            {props.text}
        </Typography>
    );
};

export const ControlSwitchDescription = (props: TextProps) => {
    return (
        <Typography variant="h6" color="#00000099">
            {props.text}
        </Typography>
    );
};

export const ListCardTitle = (props: TextProps) => {
    return (
        <Typography variant="h5" color="primary">
            {props.text}
        </Typography>
    );
};

export const ListCardText = (props: ListCardTextProps) => {
    return (
        <Stack gap={1}>
            <ListCardTitle text={props.title} />
            <Typography color="text.secondary">{props.caption}</Typography>
        </Stack>
    );
};
