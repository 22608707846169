import { Coordinates, Supplier } from 'common/types/supplier';

export const getAddress = (supplier: Supplier) => {
    return supplier.info.location.address.street + ', ' + supplier.info.location.address.locality;
};

export const getGoogleMapsLink = (coordinates: Coordinates, supplier: Supplier) => {
    return (
        'https://www.google.com/maps/dir/?api=1&origin=' +
        [coordinates.lat, coordinates.lng] +
        '&destination=' +
        getAddress(supplier)
    );
};
