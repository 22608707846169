import { useRoutes } from 'react-router-dom';

import { BaseRoutes } from './BaseRoutes';
import { AuthRoutes } from './AuthRoutes';
import { SupplierRoutes } from './SupplierRoutes';
import { UserRoutes } from './UserRoutes';
import { WebRoutes } from './WebRoutes';

export function Routes() {
    return useRoutes([BaseRoutes, AuthRoutes, SupplierRoutes, UserRoutes, WebRoutes]);
}
