import { useNavigate } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';
import { GuestGuardProps } from 'common/types/auth';
import { useEffect } from 'react';

const GuestGuard = ({ children }: GuestGuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate(-1);
        }
    }, [isLoggedIn, navigate]);

    return children;
};

export default GuestGuard;
