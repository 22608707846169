import { useGetAccurateLocation, useGetLocationByIp } from 'api/location-hooks';
import { Coordinates } from 'common/types/supplier';
import { createContext, ReactElement } from 'react';

export type LocationContextType = {
    getLocation: () => Coordinates;
    updateLocation: () => void;
};

export const LocationContext = createContext<LocationContextType | null>(null);

export const LocationProvider = ({ children }: { children: ReactElement }) => {
    const { data: location } = useGetLocationByIp();
    const getAccurateLocation = useGetAccurateLocation();

    const updateLocation = () => {
        getAccurateLocation.mutate();
    };

    const getLocation = () => {
        return location ?? { lat: 32.08048640381722, lng: 34.78069139923687 };
    };

    return <LocationContext.Provider value={{ getLocation, updateLocation }}>{children}</LocationContext.Provider>;
};
