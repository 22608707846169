import { TimeslotType, TimeslotTypeSchema } from './calendar';
import { EntityType, EntityTypeSchema } from './common';
import { isValidTimeslot } from 'common/utils/timeslots';
import * as Yup from 'yup';
import { isUri } from 'valid-url';

export type Images = {
    profile: string;
    photos: string[];
};

export const ImagesSchema = Yup.object<Record<keyof Images, Yup.AnySchema>>({
    profile: Yup.string()
        .required()
        .test('valid-uri', (value) => {
            return isUri(value ?? '') !== undefined;
        }),
    photos: Yup.array()
        .required()
        .of(
            Yup.string().test('valid-uri', (value) => {
                return isUri(value ?? '') !== undefined;
            })
        ),
});

export type Address = {
    street: string;
    locality: string;
    region: string;
    country: string;
};

export const AddressSchema = Yup.object<Record<keyof Address, Yup.AnySchema>>({
    street: Yup.string().required().min(3).max(50),
    locality: Yup.string().required().min(3).max(50),
    region: Yup.string().required().min(3).max(50),
    country: Yup.string().required().min(3).max(15),
});

export type Coordinates = {
    lat: number;
    lng: number;
};

export const CoordinatesSchema = Yup.object<Record<keyof Coordinates, Yup.AnySchema>>({
    lat: Yup.number().required().min(-90).max(90),
    lng: Yup.number().required().min(-180).max(180),
});

export type Location = {
    coordinates: Coordinates;
    address: Address;
};

export const LocationSchema = Yup.object<Record<keyof Location, Yup.AnySchema>>({
    coordinates: CoordinatesSchema.required(),
    address: AddressSchema.required(),
});

export type Social = {
    facebook?: string;
    instagram?: string;
};

export const SocialSchema = Yup.object<Record<keyof Social, Yup.AnySchema>>({
    facebook: Yup.string().url().optional(),
    instagram: Yup.string().url().optional(),
});

export type Info = {
    name: string;
    location: Location;
    phone: string;
    about: string;
    rating: number;
    category: string;
    social: Social;
};

export const InfoSchema = Yup.object<Record<keyof Info, Yup.AnySchema>>({
    name: Yup.string().required().min(3).max(30),
    location: LocationSchema.required(),
    phone: Yup.string().required().min(16).max(17),
    about: Yup.string().required().max(200),
    rating: Yup.number().required().max(5),
    category: Yup.string().required(),
    social: SocialSchema.required(),
});

export type Service = {
    label: string;
    duration: number;
    maxSeats: number;
    price?: number;
    timeslots?: TimeslotType[];
};

export const ServiceSchema = Yup.object<Record<keyof Service, Yup.AnySchema>>({
    label: Yup.string()
        .required()
        .max(40)
        .test('no whitespace at the end', (value) => {
            return value?.trimEnd() === value;
        }),
    duration: Yup.number().required().min(30).max(180),
    maxSeats: Yup.number().required().positive(),
    price: Yup.number().nullable(true).positive(),
    timeslots: Yup.array()
        .nullable(true)
        .of(
            TimeslotTypeSchema.test('no overlaps', (value, context) => {
                return isValidTimeslot(value, context.parent);
            })
        ),
});

export type Settings = {
    autoConfirm: boolean;
    showOccupiedSlots: boolean;
    showPrices: boolean;
    slotInterval: number;
    timeBetweenEvents: number;
};

export const SettingsSchema = Yup.object<Record<keyof Settings, Yup.AnySchema>>({
    autoConfirm: Yup.boolean().required(),
    showOccupiedSlots: Yup.boolean().required(),
    showPrices: Yup.boolean().required(),
    slotInterval: Yup.number().oneOf([15, 30, 45, 60, 75, 90, 120, 150, 180]).required(),
    timeBetweenEvents: Yup.number().oneOf([0, 15, 30, 45, 60]).required(),
});

export type TeamMember = {
    profile: EntityType;
    services: Service[];
    settings: Settings;
};

export const TeamMemberSchema = Yup.object<Record<keyof TeamMember, Yup.AnySchema>>({
    profile: EntityTypeSchema.required(),
    services: Yup.array()
        .required()
        .of(
            ServiceSchema.test('unique-label', (value, context) => {
                return context.parent.every((service: Service) => service === value || service.label !== value.label);
            })
        ),
    settings: SettingsSchema.required(),
});

export type Supplier = {
    _id?: string;
    images: Images;
    info: Info;
    businessHours: TimeslotType[];
    team: TeamMember[];
    owner: EntityType[];
};

export const SupplierSchema = Yup.object<Record<keyof Supplier, Yup.AnySchema>>({
    _id: Yup.string().optional().length(24),
    images: ImagesSchema.required(),
    info: InfoSchema.required(),
    businessHours: Yup.array()
        .required()
        .of(
            TimeslotTypeSchema.test('no overlaps', (value, context) => {
                return isValidTimeslot(value, context.parent);
            })
        ),
    team: Yup.array().required().of(TeamMemberSchema),
    owner: Yup.array().required().of(EntityTypeSchema),
});

export type AvailabliltyInterval = {
    seats: number;
    interval: TimeslotType;
};

export type SupplierAvailablilty = {
    supplierId: string;
    teamMemberId: string;
    serviceLabel: string;
    availability: AvailabliltyInterval[];
};

export type ContactDetails = {
    _id?: string;
    picture: string;
    fullName: string;
    email: string;
    phone: string;
};

export type SignedUrl = {
    folderName: string;
    signedUrl: string;
    publicUrl: string;
};
