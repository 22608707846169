import { EntityType, EntityTypeSchema } from './common';
import * as Yup from 'yup';

export type DateRange = { start: number | Date; end: number | Date };

export enum EventStatus {
    Created = 'Created',
    Tentative = 'Tentative',
    Scheduled = 'Scheduled',
    Verified = 'Verified',
    Cancelled = 'Cancelled',
}

export enum ReservedServiceLabel {
    Unavailable = 'Unavailable',
}

export type EventType = {
    _id?: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    creator: EntityType;
    supplier: EntityType;
    teamMember: EntityType;
    serviceLabel: string;
    reservedSlot: boolean;
    status: EventStatus;
};

export const EventTypeSchema = Yup.object<Record<keyof EventType, Yup.AnySchema>>({
    _id: Yup.string().optional().length(24),
    title: Yup.string().max(50).optional(),
    description: Yup.string().max(500),
    start: Yup.date().required(),
    end: Yup.date()
        .required()
        .test('greater than start', (value, context) => {
            const start = context.parent.start;
            if (!start || !value) {
                return true;
            }

            return value > start;
        }),
    creator: EntityTypeSchema.required(),
    supplier: EntityTypeSchema.required(),
    teamMember: EntityTypeSchema.required(),
    serviceLabel: Yup.string().required().max(40),
    reservedSlot: Yup.boolean().required(),
    status: Yup.mixed<EventStatus>().required().oneOf(Object.values(EventStatus)),
});

export type TimeslotType = {
    start: Date;
    end: Date;
};

export const TimeslotTypeSchema = Yup.object<Record<keyof TimeslotType, Yup.AnySchema>>({
    start: Yup.date().required(),
    end: Yup.date()
        .required()
        .test('greater than start', (value, context) => {
            const start = context.parent.start;
            if (!start || !value) {
                return true;
            }

            return value > start;
        }),
});

export enum ModalState {
    CLOSED = 'Closed',
    CONSUMER_CREATE_EVENT = 'ConsumerCreateEvent',
    CONSUMER_UPDATE_EVENT = 'ConsumerUpdateEvent',
    SUPPLIER_CREATE_EVENT = 'SupplierCreateEvent',
    SUPPLIER_UPDATE_EVENT = 'SupplierUpdateEvent',
    SUPPLIER_SHOW_EVENT_DETAILS = 'SupplierShowEventDetails',
}

export type ModalParams = {
    state: ModalState;
    event: Partial<EventType>;
    services?: string[];
    teamMembers?: EntityType[];
};

export type EventCallbacks = {
    onEventSelect: (event: EventType) => ModalParams;
    onRangeSelect: (range: DateRange) => ModalParams;
};
