import i18n from 'i18n/config';
import { he, enUS } from 'date-fns/locale';

export const getDateLocale = () => {
    return i18n.language === 'he-IL' ? 'he-IL' : 'en-IL';
};

export const getAdapterLocale = () => {
    return i18n.language === 'he-IL' ? he : enUS;
};

export const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === 'he-IL' ? 'en-US' : 'he-IL');
};

export const getCurrentLanguage = () => {
    return i18n.t(`components.layout.footer.locale.${i18n.language === 'he-IL' ? 'he-IL' : 'en-US'}`);
};

export const containsRtlChars = (str: string) => {
    return /[\u0590-\u05FF]/.test(str);
};
