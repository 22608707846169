import { Button, Grid } from '@mui/material';
import { AvailabliltyInterval, Service } from 'common/types/supplier';
import { displayTime } from 'common/utils/date';
import { Trans, useTranslation } from 'react-i18next';
import { TimeslotType } from 'common/types/calendar';
import { useRef, useEffect } from 'react';
import { useLayout } from 'common/hooks/useLayout';

interface AvailableTimeslotsProps {
    availability: AvailabliltyInterval[];
    service: Service;
    onSelect: (timeslot: TimeslotType) => void;
}

export const AvailableTimeslots = (props: AvailableTimeslotsProps) => {
    const { isMobile } = useLayout();
    const elementRef = useRef<HTMLDivElement | null>(null);

    const { t } = useTranslation();
    const multiSeats = props.service.maxSeats > 1;

    useEffect(() => {
        if (isMobile) {
            elementRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props, isMobile]);

    if (props.availability.length === 0) {
        return <></>;
    }

    return (
        <Grid container justifyContent="flex-start" spacing={2} ref={elementRef}>
            {props.availability.map((slot, idx) => {
                return (
                    <Grid
                        item
                        xs={multiSeats ? 6 : 3}
                        sm={multiSeats ? 6 : 3}
                        md={multiSeats ? 6 : 3}
                        lg={multiSeats ? 4 : 3}
                        key={idx}
                    >
                        <Button
                            id="button"
                            variant="outlined"
                            fullWidth
                            disabled={slot.seats === 0}
                            onClick={(e) => props.onSelect(slot.interval)}
                        >
                            {multiSeats ? (
                                <Trans
                                    i18nKey="components.schedule.availableTimeslots.multiSeatSlot"
                                    values={{
                                        start: displayTime(slot.interval.start),
                                        end: displayTime(slot.interval.end),
                                        count: slot.seats,
                                    }}
                                />
                            ) : (
                                t('components.schedule.availableTimeslots.singleSeatSlot', {
                                    start: displayTime(slot.interval.start),
                                })
                            )}
                        </Button>
                    </Grid>
                );
            })}
        </Grid>
    );
};
