import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getAdapterLocale } from 'common/utils/locale';
import { CalendarPicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { styled, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface DatePickerProps {
    value: Date;
    onChange: (value: Date | null) => void;
    minified: boolean;
}

const StyledCalendarPicker = styled('div')((props) => ({
    '& .MuiCalendarPicker-root': {
        marginLeft: '-24px',
        marginBottom: '0px',
    },
}));

export const DatePicker = (props: DatePickerProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()}>
            <DesktopDatePicker
                label={t('components.selectors.date').toString()}
                inputFormat={props.minified ? 'dd/MM' : 'dd/MM/yyyy'}
                mask={props.minified ? '__/__' : '__/__/____'}
                value={props.value}
                onChange={props.onChange}
                disablePast
                renderInput={(params) => <TextField {...params} fullWidth />}
                PaperProps={{ dir: theme.direction }}
            />
        </LocalizationProvider>
    );
};

export const DayPicker = (props: DatePickerProps) => {
    return (
        <StyledCalendarPicker>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()}>
                <CalendarPicker date={props.value} onChange={props.onChange} disablePast />
            </LocalizationProvider>
        </StyledCalendarPicker>
    );
};
