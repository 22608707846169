import { AuthGuard } from 'components/layout/Guards/AuthGuard';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from 'components/layout';
import { Confirmation } from 'views/booking/Confirmation';
import { lazy } from 'react';

const Profile = lazy(() => import('views/user'));
const ScheduledEvents = lazy(() => import('views/consumer/events'));

export const UserRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/users/profile',
            element: <Profile />,
        },
        {
            path: '/consumer/events',
            element: <ScheduledEvents />,
        },
        {
            path: '/booking/confirmation/:eventId',
            element: <Confirmation />,
        },
    ],
};
