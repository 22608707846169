import { Divider, Stack } from '@mui/material';
import { Images } from 'common/types/supplier';
import { PhotosList } from 'components/Photos';

export interface GalleryProps {
    images: Images;
}

export const Gallery = (props: GalleryProps) => {
    if (props.images.photos.length === 0) {
        return <></>;
    }

    return (
        <Stack rowGap={2}>
            <Divider flexItem />
            <PhotosList photos={props.images.photos} />
        </Stack>
    );
};
