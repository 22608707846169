import { Grid } from '@mui/material';
import { getServiceLabels } from 'common/utils/supplier';
import ListCard from 'components/cards/ListCards';
import { ListCardTitle } from 'components/Text';
import { ScheduleProps } from './ScheduleProps';

export const SelectService = (props: ScheduleProps<string>) => {
    return (
        <Grid container justifyContent="center" spacing={2}>
            {getServiceLabels(props.supplier.team).map((serviceLabel, idx) => {
                return (
                    <Grid item xs={12} key={idx}>
                        <ListCard
                            element={<ListCardTitle text={serviceLabel} />}
                            onClick={() => {
                                props.onSelect(serviceLabel);
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
