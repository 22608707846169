import { ReactElement, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, useTheme } from '@mui/material';
import { Title } from './Text';

export interface TabItem {
    label: string;
    element: ReactElement;
}

export interface TabsProps {
    items: TabItem[];
}

export interface FlowTabItem extends TabItem {
    title: string;
}

export interface FlowTabsProps {
    items: FlowTabItem[];
    index: number;
    onSelect: (index: number) => void;
}

export const Tabs = (props: TabsProps) => {
    const theme = useTheme();

    const [tabIdx, setTabIdx] = useState('0');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIdx(newValue);
    };

    return (
        <TabContext value={tabIdx}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="Tabs" style={{ direction: theme.direction }}>
                    {props.items.map((item, idx) => {
                        return <Tab key={idx} label={item.label} value={idx.toString()} />;
                    })}
                </TabList>
            </Box>
            <>
                {props.items.map((item, idx) => {
                    return (
                        <TabPanel key={idx} value={idx.toString()} sx={{ px: 0, mt: 2 }}>
                            {item.element}
                        </TabPanel>
                    );
                })}
            </>
        </TabContext>
    );
};

export const FlowTabs = (props: FlowTabsProps) => {
    const theme = useTheme();

    return (
        <TabContext value={props.index.toString()}>
            <Box sx={{ pt: 1, px: 3 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={() => {}} aria-label="Tabs" style={{ direction: theme.direction }}>
                        {props.items.map((item, idx) => {
                            return (
                                <Tab
                                    key={idx}
                                    label={
                                        <span
                                            style={{
                                                color:
                                                    idx > props.index
                                                        ? 'inherit'
                                                        : idx === props.index
                                                        ? theme.palette.primary.main
                                                        : theme.palette.primary.main + 'AA',
                                            }}
                                        >
                                            {item.label}
                                        </span>
                                    }
                                    value={idx.toString()}
                                    disabled={idx > props.index}
                                    onClick={() => props.onSelect(idx)}
                                />
                            );
                        })}
                    </TabList>
                </Box>
            </Box>
            {props.items.map((item, idx) => {
                return (
                    <TabPanel key={idx} value={idx.toString()}>
                        <Stack gap={2}>
                            <Title text={item.title} />
                            {item.element}
                        </Stack>
                    </TabPanel>
                );
            })}
        </TabContext>
    );
};
