import {
    addMinutes,
    eachMinuteOfInterval,
    endOfDay,
    format,
    isToday,
    roundToNearestMinutes,
    startOfDay,
    formatISO,
    isSameDay,
} from 'date-fns';
import { getAdapterLocale, getDateLocale } from './locale';

const daysInWeek = [
    new Date(2022, 6, 3, 0, 0, 0, 0),
    new Date(2022, 6, 4, 0, 0, 0, 0),
    new Date(2022, 6, 5, 0, 0, 0, 0),
    new Date(2022, 6, 6, 0, 0, 0, 0),
    new Date(2022, 6, 7, 0, 0, 0, 0),
    new Date(2022, 6, 8, 0, 0, 0, 0),
    new Date(2022, 6, 9, 0, 0, 0, 0),
];

const minutesInDay: Date[] = eachMinuteOfInterval({ start: daysInWeek[0], end: endOfDay(daysInWeek[0]) }, { step: 30 });

export const getMinutesInDay = () => {
    return minutesInDay;
};

export const dateToMinutesInDay = (date: Date): Date => {
    const toCheck = new Date(date);
    return (
        minutesInDay.find(
            (value) => value.getHours() === toCheck.getHours() && value.getMinutes() === toCheck.getMinutes()
        ) ?? minutesInDay[0]
    );
};

export const getDaysInWeek = () => {
    return daysInWeek.map((value) =>
        value.toLocaleDateString(getDateLocale(), {
            weekday: 'long',
        })
    );
};

export const dateToDayString = (date: Date) => {
    return new Date(date).toLocaleDateString(getDateLocale(), {
        weekday: 'long',
    });
};

export const displayTime = (date: Date) => {
    return format(new Date(date), 'HH:mm');
};

export const displayDate = (date: Date) => {
    return new Date(date).toLocaleDateString(getDateLocale(), {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
    });
};

export const dayToDate = (name: string, timeOfDay: Date) => {
    const timeDate = new Date(timeOfDay);

    for (let i = 0; i < daysInWeek.length; i++) {
        if (dateToDayString(daysInWeek[i]) === name) {
            const date = new Date(daysInWeek[i]);

            date.setHours(timeDate.getHours());
            date.setMinutes(timeDate.getMinutes());

            return date;
        }
    }

    return timeDate;
};

export const formatDate = (date: Date) => {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
};

export const toGoogleFormat = (date: Date) => {
    return formatISO(new Date(date), { format: 'basic' });
};

export const getDayIdx = (date: Date) => {
    return new Date(date).getDay();
};

export const toMs = (date: Date | number) => {
    return new Date(date).getTime();
};

export const generateTimeInterval = (date: Date) => {
    const start = isToday(date)
        ? roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 30 })
        : startOfDay(date);

    const end = roundToNearestMinutes(endOfDay(date));

    return {
        start: isSameDay(start, date) ? start : end,
        end: end,
    };
};

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getMonth = (date: Date) => {
    return format(date, 'MMMM', { locale: getAdapterLocale() });
};
