import { Button, Grid } from '@mui/material';
import { IconMap2, IconPhone, IconInfoSquare } from '@tabler/icons-react';
import { Supplier } from 'common/types/supplier';
import { getAddress } from 'common/utils/location';
import { IconWithText } from 'components/Icons';
import { Section, WebOnly } from 'components/layout';
import { Title } from 'components/Text';
import { Image } from 'components/Image';
import { useLayout } from 'common/hooks/useLayout';

export interface SupplierCardProps {
    supplier: Supplier;
    onSelect: () => void;
}

export const SupplierCard = (props: SupplierCardProps) => {
    const { isMobile } = useLayout();

    return (
        <Section>
            <Button
                sx={{ width: '100%', padding: 0, border: '1px solid #D8D9DB', borderRadius: '4px' }}
                onClick={props.onSelect}
            >
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={5}>
                        <Image
                            sx={{
                                height: { xs: '30vh', sm: '35vh' },
                                borderRadius: isMobile ? '4px 4px 0 0' : '4px 0 0 4px',
                            }}
                            src={props.supplier.images.profile}
                            alt={props.supplier.info.name}
                        />
                    </Grid>
                    <Grid item xs={0} sm={1} />
                    <Grid item xs={12} sm={6} sx={{ py: 2, px: 2 }}>
                        <Grid container justifyContent="start" textAlign="start" rowSpacing={2}>
                            <Grid item xs={12}>
                                <Title text={props.supplier.info.name} />
                            </Grid>
                            <Grid item xs={12}>
                                <IconWithText icon={IconMap2} text={getAddress(props.supplier)} />
                            </Grid>
                            <Grid item xs={12}>
                                <IconWithText icon={IconPhone} text={props.supplier.info.phone} />
                            </Grid>
                            <WebOnly>
                                <Grid item xs={12}>
                                    <IconWithText icon={IconInfoSquare} text={props.supplier.info.about} />
                                </Grid>
                            </WebOnly>
                        </Grid>
                    </Grid>
                </Grid>
            </Button>
        </Section>
    );
};
