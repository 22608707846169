import { RouteObject } from 'react-router-dom';
import { WebLayout } from 'components/layout';
import { lazy } from 'react';

const LandingPage = lazy(() => import('views/landing'));

export const WebRoutes: RouteObject = {
    path: '/landing',
    element: <WebLayout />,
    children: [
        {
            path: '',
            element: <LandingPage />,
        },
    ],
};
