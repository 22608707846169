import { AppBar, Box, Drawer, IconButton, Toolbar, useTheme, Grid } from '@mui/material';
import { Logo } from 'components/layout/Header/Logo';
import { IconMenu2, IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { SelectSupplierMinified } from 'views/search/SelectSupplierMinified';
import { Icon } from '../../Icons';

export interface HeaderProps {
    onMenuClick: () => void;
}

interface MenuProps {
    onClick: () => void;
}

const MenuSection = (props: MenuProps) => {
    return (
        <IconButton size="medium" color="inherit" onClick={props.onClick}>
            <Icon icon={IconMenu2} />
        </IconButton>
    );
};

const SearchSection = (props: MenuProps) => {
    return (
        <IconButton size="medium" color="inherit" onClick={props.onClick}>
            <Icon icon={IconSearch} />
        </IconButton>
    );
};

export const AppHeader = (props: HeaderProps) => {
    const theme = useTheme();
    const [searchOpen, setSearchOpen] = useState<boolean>(false);

    return (
        <AppBar
            enableColorOnDark
            position="static"
            elevation={0}
            color="inherit"
            sx={{ height: 55 }}
            dir={theme.direction}
        >
            <Toolbar
                style={{ minHeight: 55 }}
                sx={{ px: { xs: 2, sm: '8%', md: '10%', lg: '20%' }, borderBottom: 1, borderColor: 'divider' }}
            >
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <MenuSection onClick={props.onMenuClick} />
                    </Grid>
                    <Grid item>
                        <Logo />
                    </Grid>
                    <Grid item>
                        <SearchSection onClick={() => setSearchOpen(true)} />
                    </Grid>
                </Grid>
            </Toolbar>
            <>
                <Drawer anchor="top" open={searchOpen} onClose={() => setSearchOpen(false)} dir={theme.direction}>
                    <SelectSupplierMinified date={new Date()} category="" onSubmit={() => setSearchOpen(false)} />
                </Drawer>
            </>
        </AppBar>
    );
};

export const WebHeader = () => {
    const theme = useTheme();

    return (
        <AppBar
            enableColorOnDark
            position="static"
            elevation={0}
            color="inherit"
            sx={{ height: '55px', justifyContent: 'center' }}
            dir={theme.direction}
        >
            <Toolbar sx={{ px: { xs: 2, sm: '8%', md: '13%', lg: '20%' } }}>
                <Box sx={{ flexGrow: 1 }} />
                <Logo />
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </AppBar>
    );
};
