import { Box, Grid, useTheme } from '@mui/material';
import { LanguageSelection } from 'components/LanguageSelection';
import { FooterLink } from 'components/Links';
import { FooterCaption, FooterTitle } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { Section } from '..';

export const Footer = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <footer dir={theme.direction}>
            <Box sx={{ backgroundColor: '#EFF2F6', color: '#4F4F4F', mt: '20vh' }}>
                <Section>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12}>
                            <FooterTitle text={t('components.layout.footer.links.title')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FooterLink label={t('components.layout.footer.links.createAccount')} link="/landing" />
                        </Grid>
                        <Grid item xs={12}>
                            <FooterLink label={t('components.layout.footer.links.contact')} link="/contact" />
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 4 }}></Grid>
                        <Grid item xs={8}>
                            <LanguageSelection />
                        </Grid>
                        <Grid item xs={4}>
                            <FooterCaption text={t('components.layout.footer.copyright')} />
                        </Grid>
                    </Grid>
                </Section>
            </Box>
        </footer>
    );
};
