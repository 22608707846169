import i18n from 'i18next';
import en from './en.json';
import he from './he.json';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

i18n.use(detector)
    .use(initReactI18next)
    .init({
        lng: 'he-IL',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: en,
            he: he,
        },
    });

export default i18n;
