import axios from 'axios';
import { EventType } from 'common/types/calendar';

import {
    EVENTS_API,
    EVENT_UPDATE_STATUS_API,
    EVENTS_SUPPLIER_OWNER_API,
    EVENTS_SUPPLIER_TEAM_MEMBER_API,
    EVENTS_USER_API,
    EVENT_API,
} from './routes';

export interface UpdateEventStatusParams {
    eventId: string;
    eventStatus: string;
}

export const getEventsByUserId: (userId: string) => Promise<EventType[]> = async (userId) => {
    const res = await axios.get(EVENTS_USER_API(userId));
    return res.data;
};

export const getEventsBySupplierId: (supplierId: string) => Promise<EventType[]> = async (supplierId) => {
    const res = await axios.get(EVENTS_SUPPLIER_OWNER_API(supplierId));
    return res.data;
};

export const getEventsByTeamMemberId: (supplierId: string, teamMemberId: string) => Promise<EventType[]> = async (
    supplierId,
    teamMemberId
) => {
    const res = await axios.get(EVENTS_SUPPLIER_TEAM_MEMBER_API(supplierId, teamMemberId));
    return res.data;
};

export const getEvent: (eventId: string) => Promise<EventType> = async (eventId) => {
    const res = await axios.get(EVENT_API(eventId));
    return res.data;
};

export const createEvent: (event: EventType) => Promise<EventType> = async (event) => {
    const res = await axios.post(EVENTS_API, event);
    return res.data;
};

export const updateEvent: (event: EventType) => Promise<EventType> = async (event) => {
    const res = await axios.put(EVENT_API(event._id!), event);
    return res.data;
};

export const deleteEvent: (eventId: string) => Promise<void> = async (eventId) => {
    await axios.delete(EVENT_API(eventId));
};

export const updateEventStatus: (params: UpdateEventStatusParams) => Promise<EventType> = async (params) => {
    const res = await axios.post(EVENT_UPDATE_STATUS_API(params.eventId), { status: params.eventStatus });
    return res.data;
};
