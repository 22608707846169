import { createContext, useState } from 'react';

import { AuthContextType, AuthResponse, LoginCallbacks, LogoutCallbacks, UserProfile } from 'common/types/auth';
import {
    useGetAuthToken,
    useGetGoogleLoginUrl,
    useLocalLogin,
    useLocalRegister,
    useRevokeAuthToken,
} from 'api/auth-hooks';
import { invalidateToken, setToken } from 'api/utils';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<UserProfile | null>(null);

    const generateNonce = () => {
        return uuidv4();
    };

    const [nonce, setNonce] = useState<string>(generateNonce());
    const { data: loginUrl } = useGetGoogleLoginUrl({ state: nonce });

    const getAuthToken = useGetAuthToken();
    const revokeAuthToken = useRevokeAuthToken();
    const localLogin = useLocalLogin();
    const localRegister = useLocalRegister();

    const onLoginSuccess = (data: AuthResponse, callback: (user: UserProfile) => void) => {
        setToken(data.token);
        setUser(data.user);
        setLoggedIn(true);

        setTimeout(() => callback(data.user), 500);
    };

    const generateEventHandler = (
        resolve: (value: string | PromiseLike<string>) => void,
        reject: (reason?: any) => void,
        title: string,
        nonce: string
    ) => {
        return (event: MessageEvent) => {
            if (event.origin !== window.location.origin) {
                return;
            }

            //@ts-ignore
            if (event.source?.name !== title) {
                return;
            }

            if (event.data.state !== nonce) {
                reject('Invalid state');
            }

            if (!event.data.code) {
                resolve('Invalid code');
            }

            resolve(event.data.code!);
        };
    };

    const loginWithPopup = async (): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            const target = 'GoogleLogin';

            const top = window.outerHeight / 2 + window.screenY - 550 / 2;
            const left = window.outerWidth / 2 + window.screenX - 300 / 2;

            const eventHandler = generateEventHandler(resolve, reject, target, nonce);
            window.addEventListener('message', eventHandler);

            const popup = window.open(loginUrl, target, `popup, width=300, height=550, top=${top}, left=${left}`);
            popup?.focus();
        });
    };

    const loginWithGoogle = async (callbacks: LoginCallbacks) => {
        if (isLoggedIn) {
            callbacks.onSuccess(user!);
            return;
        }

        try {
            const code = await loginWithPopup();

            getAuthToken.mutate(code, {
                onSuccess: (data: AuthResponse) => {
                    onLoginSuccess(data, callbacks.onSuccess);
                },
                onError: callbacks.onError,
            });
        } catch (err) {}

        setNonce(generateNonce());
    };

    const login = async (email: string, password: string, callbacks: LoginCallbacks) => {
        if (isLoggedIn) {
            callbacks.onSuccess(user!);
            return;
        }

        localLogin.mutate(
            { email: email, password: password },
            {
                onSuccess: (data: AuthResponse) => {
                    onLoginSuccess(data, callbacks.onSuccess);
                },
                onError: callbacks.onError,
            }
        );
    };

    const register = async (user: UserProfile, password: string, callbacks: LoginCallbacks) => {
        localRegister.mutate(
            { user: user, password: password },
            {
                onSuccess: (data: AuthResponse) => {
                    onLoginSuccess(data, callbacks.onSuccess);
                },
                onError: callbacks.onError,
            }
        );
    };

    const logout = async (callbacks: LogoutCallbacks) => {
        revokeAuthToken.mutate();

        invalidateToken();
        setLoggedIn(false);
        setUser(null);

        callbacks.onSuccess();
    };

    const updateUserDetails = (user: UserProfile) => {
        setUser(user);
        setLoggedIn(true);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, user, login, register, loginWithGoogle, logout, updateUserDetails }}>
            {children}
        </AuthContext.Provider>
    );
};
