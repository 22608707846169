import { Routes } from 'routes';
import { AuthProvider } from 'contexts/auth/AuthContext';
import { NotificationProvider } from 'contexts/notifications/NotificationsContext';
import { LocationProvider } from 'contexts/locations/LocationsContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StyleProvider } from 'contexts/themes/ThemeContext';
import { Suspense } from 'react';

import 'i18n/config';
import 'common/infra/sentry';
import { SupplierProvider } from 'contexts/suppliers/SuppliersContext';
import { LayoutProvider } from 'contexts/layout/LayoutContext';

const queryClient = new QueryClient();

const App = () => {
    return (
        <StyleProvider>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <SupplierProvider>
                        <NotificationProvider>
                            <LocationProvider>
                                <LayoutProvider>
                                    <Suspense>
                                        <Routes />
                                    </Suspense>
                                </LayoutProvider>
                            </LocationProvider>
                        </NotificationProvider>
                    </SupplierProvider>
                </AuthProvider>
            </QueryClientProvider>
        </StyleProvider>
    );
};

export default App;
