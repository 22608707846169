import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { Sidebar } from 'components/layout/Sidebar';
import { Outlet } from 'react-router-dom';
import { AppHeader, WebHeader } from './Header';
import { Footer } from './Footer';
import { ReactElement, useState } from 'react';
import { useLayout } from 'common/hooks/useLayout';

export interface SectionProps {
    children: ReactElement | ReactElement[] | null;
    sx?: SxProps<Theme>;
}

export const MainLayout = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const onMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <AppHeader onMenuClick={onMenuClick} />
            <Sidebar isOpen={menuOpen} handleToggle={onMenuClick} />
            <main>
                <Outlet />
            </main>

            <Box sx={{ flexGrow: 1 }} />
            <Footer />
        </Box>
    );
};

export const WebLayout = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <WebHeader />
            <main>
                <Outlet />
            </main>

            <Box sx={{ flexGrow: 1 }} />
            <Footer />
        </Box>
    );
};

export const Section = (props: SectionProps) => {
    const theme = useTheme();

    const defaultStyle = {
        pl: { xs: 2, sm: '8%', md: '10%', lg: '20%' },
        pr: { xs: 2, sm: '8%', md: '10%', lg: '20%' },
        pt: { xs: 2, sm: 4, md: 5, lg: 5 },
        pb: { xs: 2, sm: 4, md: 5, lg: 5 },
    };

    return (
        <Box sx={{ ...defaultStyle, ...props.sx }} style={{ direction: theme.direction }}>
            {props.children}
        </Box>
    );
};

export const MobileOnly = ({ children }: { children: ReactElement | ReactElement[] }) => {
    const { isMobile } = useLayout();

    if (!isMobile) {
        return <></>;
    }

    return <>{children}</>;
};

export const WebOnly = ({ children }: { children: ReactElement | ReactElement[] }) => {
    const { isMobile } = useLayout();

    if (isMobile) {
        return <></>;
    }

    return <>{children}</>;
};
