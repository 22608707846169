import axios from 'axios';
import { QueryOptions } from './utils';

import {
    SUPPLIERS_API,
    SUPPLIERS_DISTINCT_CATEGORIES_API,
    SUPPLIERS_SEARCH_API,
    SUPPLIER_API,
    SUPPLIER_AVAILABILITY_API,
    SUPPLIER_TEAM_MEMBERS_API,
} from './routes';
import { ContactDetails, Coordinates, Supplier, SupplierAvailablilty } from 'common/types/supplier';

export interface SupplierByCategoryQueryOptions extends QueryOptions {
    category: string;
    start: Date;
    end: Date;
    sort: string;
    coordinates: Coordinates;
}

export interface SupplierSearchOptions extends QueryOptions {
    query: string;
}

export interface SupplierAvailabilityOptions extends QueryOptions {
    start: Date;
    end: Date;
    teamMemberId: string;
    serviceLabel: string;
}

// Supplier APIs
export const getSuppliersByCategory: (opts: SupplierByCategoryQueryOptions) => Promise<Supplier[]> = async (opts) => {
    const res = await axios.get(SUPPLIERS_API, { params: opts });
    return res.data;
};

export const getSupplier: (supplierId: string) => Promise<Supplier> = async (supplierId) => {
    const res = await axios.get(SUPPLIER_API(supplierId));
    return res.data;
};

export const getSupplierTeamMembers: (supplierId: string) => Promise<ContactDetails[]> = async (supplierId) => {
    const res = await axios.get(SUPPLIER_TEAM_MEMBERS_API(supplierId));
    return res.data;
};

export const createSupplier: (supplier: Supplier) => Promise<Supplier> = async (supplier) => {
    const res = await axios.post(SUPPLIERS_API, supplier);
    return res.data;
};

export const updateSupplier: (supplier: Supplier) => Promise<Supplier> = async (supplier) => {
    const res = await axios.put(SUPPLIER_API(supplier._id!), supplier);
    return res.data;
};

export const deleteSupplier: (supplierId: string) => Promise<void> = async (supplierId) => {
    await axios.delete(SUPPLIER_API(supplierId));
    return;
};

// get available timeslots for a specific supplier on a specific date
export const getAvailableTimeslotsForSupplier: (
    supplierId: string,
    opts: SupplierAvailabilityOptions
) => Promise<SupplierAvailablilty> = async (supplierId, opts) => {
    const res = await axios.get(SUPPLIER_AVAILABILITY_API(supplierId), { params: opts });
    return res.data;
};

export const searchSuppliers: (query: SupplierSearchOptions) => Promise<Supplier[]> = async (query) => {
    const res = await axios.get(SUPPLIERS_SEARCH_API, { params: query });
    return res.data;
};

export const getDistinctCategories: () => Promise<string[]> = async () => {
    const res = await axios.get(SUPPLIERS_DISTINCT_CATEGORIES_API);
    return res.data;
};
