import axios from 'axios';

import { USERS_API, USER_API, USER_ROLES_API } from './routes';
import { UserProfile, UserRoles } from 'common/types/auth';

export const getUserRoles: (userId: string) => Promise<UserRoles> = async (userId) => {
    const res = await axios.get(USER_ROLES_API(userId));
    return res.data;
};

export const getUserProfile: (userId: string) => Promise<UserProfile> = async (userId) => {
    const res = await axios.get(USER_API(userId));
    return res.data;
};

export const createUser: (user: UserProfile) => Promise<UserProfile> = async (user) => {
    const res = await axios.post(USERS_API, user);
    return res.data;
};

export const updateUser: (user: UserProfile) => Promise<UserProfile> = async (user) => {
    const res = await axios.put(USER_API(user._id!), user);
    return res.data;
};

export const deleteUser: (userId: string) => Promise<void> = async (userId) => {
    await axios.delete(USER_API(userId));
    return;
};
