import { useNavigate } from 'react-router-dom';
import { useAuth } from 'common/hooks/useAuth';
import { AuthGuardProps } from 'common/types/auth';
import { useEffect } from 'react';
import { isFacebookApp } from 'common/utils/browser';

export const AuthGuard = (props: AuthGuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            if (isFacebookApp()) {
                navigate('/auth/register', { replace: false });
            } else {
                navigate('/auth/login', { replace: false });
            }
        }
    }, [isLoggedIn, navigate]);

    return props.children;
};
