import { Box, Grid, Stack, Skeleton } from '@mui/material';
import { useGetAvailableTimeslotsForSupplier } from 'api/suppliers-hooks';
import { TimeslotType } from 'common/types/calendar';
import { displayDate, generateTimeInterval } from 'common/utils/date';
import { generateIntervalSlots } from 'common/utils/timeslots';
import { DayPicker } from 'components/DatePicker';
import { ListCardText } from 'components/Text';
import { Loading } from 'components/Transitions';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableTimeslots } from './AvailableTimeslots';
import { SelectTimeslotProps } from './ScheduleProps';

export const SelectTimeslot = (props: SelectTimeslotProps) => {
    const { t } = useTranslation();

    const [date, setDate] = useState<Date>(props.date);

    const { data: supplierAvailability, isLoading } = useGetAvailableTimeslotsForSupplier(props.supplier._id!, {
        ...generateTimeInterval(date),
        teamMemberId: props.teamMember.profile._id!,
        serviceLabel: props.service.label,
    });

    const allSlots = useMemo(() => {
        if (!supplierAvailability) {
            return [];
        }

        const slots = supplierAvailability.availability
            .map((item) =>
                generateIntervalSlots(
                    item.interval,
                    item.seats,
                    props.service.duration,
                    props.teamMember.settings.slotInterval
                )
            )
            .reduce((arr, slots) => arr.concat(slots), []);

        return slots;
    }, [supplierAvailability, props]);

    const onlyAvailableSlots = useMemo(() => {
        if (!allSlots) {
            return [];
        }

        return allSlots.filter((item) => item.seats > 0);
    }, [allSlots]);

    const renderNumSlots = () => {
        return (
            <Box sx={{ pb: 5, textAlign: 'start' }}>
                <Loading
                    loading={isLoading}
                    loadingElement={
                        <Stack gap={1}>
                            <Skeleton width={180} animation="wave" />
                            <Skeleton variant="rounded" height={50} animation="wave" />
                        </Stack>
                    }
                >
                    <ListCardText
                        title={t('components.schedule.selectTimeslot.availability', {
                            count: onlyAvailableSlots.length,
                        })}
                        caption={t('components.schedule.selectTimeslot.criteria', {
                            serviceLabel: props.service.label,
                            teamMember: props.teamMember.profile.name,
                            date: displayDate(date),
                        })}
                    />
                </Loading>
            </Box>
        );
    };

    return (
        <Grid container justifyContent="start" alignItems="center">
            <Grid item>
                <DayPicker
                    value={date}
                    onChange={(value) => {
                        setDate(value ?? date);
                    }}
                    minified={false}
                />
            </Grid>
            <Grid item xs>
                {renderNumSlots()}
            </Grid>
            <Grid item xs={12}>
                <Loading loading={isLoading} loadingElement={<></>}>
                    <AvailableTimeslots
                        availability={props.teamMember.settings.showOccupiedSlots ? allSlots : onlyAvailableSlots}
                        service={props.service}
                        onSelect={(timeslot: TimeslotType) => {
                            props.onSelect(timeslot);
                        }}
                    />
                </Loading>
            </Grid>
        </Grid>
    );
};
