import axios from 'axios';
import { AuthResponse, UserProfile } from 'common/types/auth';
import {
    AUTH_GOOGLE_LOGIN_API,
    AUTH_LOGOUT_API,
    AUTH_GOOGLE_TOKEN_API,
    AUTH_LOGIN_API,
    AUTH_REGISTER_API,
} from './routes';
import { QueryOptions } from './utils';

export interface GoogleLoginOptions extends QueryOptions {
    state: string;
}

export interface LoginParams {
    email: string;
    password: string;
}

export interface RegisterParams {
    user: UserProfile;
    password: string;
}

export const getGoogleLoginUrl: (opts: GoogleLoginOptions) => Promise<string> = async (opts) => {
    const res = await axios.get(AUTH_GOOGLE_LOGIN_API, { params: opts });
    return res.data;
};

export const getAuthToken: (code: string) => Promise<AuthResponse> = async (code) => {
    const res = await axios.post(AUTH_GOOGLE_TOKEN_API, { code: code });
    return res.data;
};

export const login: (params: LoginParams) => Promise<AuthResponse> = async (params) => {
    const res = await axios.post(AUTH_LOGIN_API, params);
    return res.data;
};

export const register: (params: RegisterParams) => Promise<AuthResponse> = async (params) => {
    const res = await axios.post(AUTH_REGISTER_API, params);
    return res.data;
};

export const revokeAuthToken: () => Promise<boolean> = async () => {
    const res = await axios.post(AUTH_LOGOUT_API);
    return res.data;
};
