import { Autocomplete, Divider, Grid, InputAdornment, TextField, useTheme } from '@mui/material';
import { ActionButton } from 'components/Buttons';
import { Selector } from 'components/Selectors';
import { TextHeader } from 'components/Text';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SupplierSearchParams } from 'views/search';
import { MobileOnly, Section } from 'components/layout';
import { IconSearch } from '@tabler/icons-react';
import { Supplier } from 'common/types/supplier';
import { useGetDistinctCategories, useSearchSuppliers } from 'api/suppliers-hooks';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/DatePicker';

export const SelectSupplier = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const { t } = useTranslation();

    const { data: categories } = useGetDistinctCategories();

    const [query, setQuery] = useState<string>('');
    const { data: suppliers } = useSearchSuppliers({ query: query });

    const formik = useFormik({
        initialValues: { category: '', date: new Date() },
        validationSchema: Yup.object().shape({
            category: Yup.string().required(),
            date: Yup.date().required(),
        }),
        onSubmit: async (values) => {
            if (!values.category || !values.date) {
                return;
            }

            searchParams.set(SupplierSearchParams.CATEGORY, values.category);
            searchParams.set(SupplierSearchParams.DATE, values.date.toJSON());

            navigate({ pathname: '/search', search: searchParams.toString() });
        },
    });

    const onClick = async () => {
        await formik.submitForm();
    };

    const onSupplierSelect = (value: Supplier | string) => {
        if (typeof value === 'string') {
            return;
        }

        navigate('/suppliers/' + value._id!);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate dir={theme.direction}>
                <Section>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ my: { xs: 2, md: 4 } }}
                    >
                        <Grid item xs={12} textAlign="center">
                            <TextHeader text={t('views.home.selectSupplier.title')} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Selector
                                value={formik.values.category}
                                label={t('components.selectors.category.label')}
                                options={categories ?? []}
                                onSelectionChange={async (value) =>
                                    await formik.setValues({ ...formik.values, category: value })
                                }
                                displayOption={(option: any) => t(`components.selectors.category.options.${option}`)}
                                error={Boolean(formik.touched.category && formik.errors.category)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker
                                value={formik.values.date}
                                onChange={async (value) => await formik.setValues({ ...formik.values, date: value! })}
                                minified={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ActionButton label={t('views.home.selectSupplier.action')} onClick={onClick} />
                        </Grid>
                        <MobileOnly>
                            <Grid item xs={12} md={9}>
                                <Divider sx={{ my: 1 }} />
                            </Grid>
                        </MobileOnly>
                        <Grid item xs={12} md={9}>
                            <Autocomplete
                                freeSolo
                                id="search"
                                disableClearable
                                options={suppliers ?? []}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.info.name)}
                                onChange={(e, value) => {
                                    onSupplierSelect(value);
                                }}
                                groupBy={(option) => t(`components.selectors.category.options.${option.info.category}`)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('views.home.selectSupplier.search').toString()}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconSearch />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Section>
            </Form>
        </FormikProvider>
    );
};
