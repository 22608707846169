import { LoadingButton } from '@mui/lab';
import { Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLayout } from 'common/hooks/useLayout';

export interface ButtonProps {
    label: string;
    onClick: () => void;
}

export interface LoadingButtonProps {
    label: string;
    loading: boolean;
    onClick: () => void;
}

export interface LinkButtonProps {
    label: string;
    to: string;
    size: 'medium' | 'large';
    variant: 'contained' | 'outlined';
    color: 'primary' | 'secondary';
}

export const ActionButton = (props: ButtonProps) => {
    return (
        <Button
            variant="contained"
            type="button"
            disableElevation
            fullWidth
            size="large"
            sx={{
                '&:hover': {
                    backgroundColor: 'primary.main',
                },
            }}
            onClick={props.onClick}
        >
            {props.label}
        </Button>
    );
};

export const MediumActionButton = (props: ButtonProps) => {
    return (
        <Button
            variant="contained"
            disableElevation
            fullWidth
            size="medium"
            sx={{
                '&:hover': {
                    backgroundColor: 'primary.main',
                },
            }}
            onClick={props.onClick}
        >
            {props.label}
        </Button>
    );
};

export const UpdateLocationButton = (props: ButtonProps) => {
    const theme = useTheme();

    return (
        <Button
            variant="text"
            disableElevation
            size="small"
            sx={{
                fontSize: theme.typography.body2.fontSize,
                padding: 0,
            }}
            onClick={props.onClick}
        >
            {props.label}
        </Button>
    );
};

export const ConfirmMeetingButton = (props: LoadingButtonProps) => {
    const { isMobile } = useLayout();

    return (
        <LoadingButton
            variant={isMobile ? 'contained' : 'outlined'}
            disableElevation
            fullWidth
            size="large"
            loading={props.loading}
            onClick={props.onClick}
            sx={isMobile ? { my: 4 } : {}}
        >
            {props.label}
        </LoadingButton>
    );
};

export const LinkButton = (props: LinkButtonProps) => {
    return (
        <Button
            component={Link}
            to={props.to}
            rel="noopener noreferrer"
            size={props.size}
            variant={props.variant}
            color={props.color}
        >
            {props.label}
        </Button>
    );
};
