import { Box, ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import { Image } from '../../Image';

import LogoImage from 'assets/images/logo/logo.svg';

export const Logo = () => {
    return (
        <Box sx={{ display: 'flex', height: 55, justifyContent: 'center', alignItems: 'center' }}>
            <ButtonBase disableRipple component={Link} to={'/'}>
                <Image sx={{ height: 25, width: 102 }} src={LogoImage} alt="Logo" />
            </ButtonBase>
        </Box>
    );
};
