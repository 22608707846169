import { Stack, Link } from '@mui/material';
import { Title } from 'components/Text';
import { IconWithText } from 'components/Icons';

import { IconMail, IconBrandWhatsapp, IconBrandFacebook } from '@tabler/icons-react';
import { Section } from 'components/layout';
import { useTranslation } from 'react-i18next';
import { mailLink, whatsappLink } from 'common/utils/browser';

export const Contact = () => {
    const { t } = useTranslation();

    const facebook = 'https://www.facebook.com/profile.php?id=100086307564997';
    const email = 'eyal@bookalele.com';
    const support = '+972549222148';

    return (
        <Section>
            <Stack rowGap={2}>
                <Title text={t('views.contact.title')} />
                <Link href={facebook} underline="none" color="inherit" target="_blank">
                    <IconWithText icon={IconBrandFacebook} text={t('views.contact.facebook.label')} />
                </Link>
                <Link href={mailLink(email)} underline="none" color="inherit">
                    <IconWithText icon={IconMail} text={email} />
                </Link>
                <Link href={whatsappLink(support, t('views.contact.whatsapp.text'))} underline="none" color="inherit">
                    <IconWithText icon={IconBrandWhatsapp} text={t('views.contact.whatsapp.label')} />
                </Link>
            </Stack>
        </Section>
    );
};
