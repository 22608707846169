import GuestGuard from 'components/layout/Guards/GuestGuard';

import { LoginPage, LoginSuccess } from 'views/auth/Login';
import { MainLayout } from 'components/layout';
import { lazy } from 'react';

const Register = lazy(() => import('views/auth/Register'));

export const AuthRoutes = {
    path: 'auth',
    element: <MainLayout />,
    children: [
        {
            path: 'login',
            element: (
                <GuestGuard>
                    <LoginPage />
                </GuestGuard>
            ),
        },
        {
            path: 'register',
            element: <Register />,
        },
        {
            path: 'login/success',
            element: <LoginSuccess />,
        },
    ],
};
