import { Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { Supplier } from 'common/types/supplier';
import {
    IconMap2,
    IconPhone,
    IconBrandFacebook,
    IconBrandInstagram,
    IconClock,
    IconBrandWhatsapp,
    IconBrandWaze,
} from '@tabler/icons-react';
import { IconWithElement, IconWithText, HoverColoredIcon } from 'components/Icons';
import { displayTime, getDaysInWeek } from 'common/utils/date';
import { Title } from 'components/Text';
import { useLocations } from 'common/hooks/useLocations';
import { getAddress, getGoogleMapsLink } from 'common/utils/location';
import { Image } from 'components/Image';
import { useTranslation } from 'react-i18next';
import { phoneLink, wazeLink, whatsappLink } from 'common/utils/browser';
import { groupTimeslotsByDay } from 'common/utils/timeslots';
import { TimeslotType } from 'common/types/calendar';
import { useMemo } from 'react';
import Map from 'components/Map';
import { MediumActionButton } from 'components/Buttons';
import { MobileOnly } from 'components/layout';
import { Gallery } from './Gallery';

interface InfoSectionProps {
    supplier: Supplier;
}

export interface InfoProps extends InfoSectionProps {
    onSchedule: () => void;
}

const BusinessHours = (props: InfoSectionProps) => {
    const { t } = useTranslation();

    const businessHours = useMemo(() => {
        return groupTimeslotsByDay(props.supplier.businessHours ?? []);
    }, [props.supplier]);

    return (
        <Grid item xs={11}>
            <IconWithElement
                icon={IconClock}
                element={
                    <Grid container justifyContent="space-between">
                        {businessHours.map((timeslots: TimeslotType[], idx: number) => {
                            return (
                                <Grid container key={idx}>
                                    <Grid item xs={4} sm={3} md={5}>
                                        <Typography noWrap>{getDaysInWeek()[idx]}</Typography>
                                    </Grid>
                                    <Grid item xs={8} sm={9} md={7}>
                                        {t('views.supplier.home.info.businessHours.timeslots', {
                                            count: timeslots.length,
                                            start: timeslots.length > 0 ? displayTime(timeslots[0].start) : null,
                                            end:
                                                timeslots.length > 0
                                                    ? displayTime(timeslots[timeslots.length - 1].end)
                                                    : null,
                                        }) + '\n'}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                }
            />
        </Grid>
    );
};

const SocialLinks = (props: InfoSectionProps) => {
    return (
        <Grid item xs={11}>
            <Stack direction="row" justifyContent="center">
                <HoverColoredIcon
                    link={props.supplier.info.social.facebook ?? ''}
                    color="#1876f2"
                    icon={IconBrandFacebook}
                    hide={!props.supplier.info.social.facebook}
                />
                <HoverColoredIcon
                    link={props.supplier.info.social.instagram ?? ''}
                    color="#C13584"
                    icon={IconBrandInstagram}
                    hide={!props.supplier.info.social.instagram}
                />
                <HoverColoredIcon
                    link={whatsappLink(props.supplier.info.phone, '')}
                    color="#25D366"
                    icon={IconBrandWhatsapp}
                    hide={!props.supplier.info.phone}
                />
                <HoverColoredIcon
                    link={wazeLink(props.supplier.info.location)}
                    color="#05c8f7"
                    icon={IconBrandWaze}
                    hide={!props.supplier.info.location}
                />
            </Stack>
        </Grid>
    );
};

export const Info = (props: InfoProps) => {
    const { t } = useTranslation();
    const { getLocation } = useLocations();

    return (
        <Grid container justifyContent="space-around" rowGap={2}>
            <Grid item xs={12}>
                <Image
                    sx={{ height: 275, borderRadius: '4px', filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
                    src={props.supplier.images.profile}
                    alt={props.supplier.info.name}
                />
            </Grid>
            <Grid container rowGap={2} justifyContent="center">
                <Grid item xs={12} textAlign="center">
                    <Title text={props.supplier.info.name} />
                </Grid>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h5" sx={{ whiteSpace: 'pre-wrap' }}>
                        {props.supplier.info.about}
                    </Typography>
                </Grid>
                <SocialLinks supplier={props.supplier} />
                <MobileOnly>
                    <Grid item xs={11}>
                        <MediumActionButton label={t('views.supplier.home.info.schedule')} onClick={props.onSchedule} />
                    </Grid>
                </MobileOnly>
                <Grid item xs={12}>
                    <Divider flexItem />
                </Grid>
                <Grid item xs={11}>
                    <Link
                        href={getGoogleMapsLink(getLocation(), props.supplier)}
                        color="inherit"
                        underline="hover"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <IconWithText icon={IconMap2} text={getAddress(props.supplier)} />
                    </Link>
                </Grid>
                <Grid item xs={11}>
                    <Link href={phoneLink(props.supplier.info.phone)} color="inherit" underline="hover">
                        <IconWithText icon={IconPhone} text={props.supplier.info.phone} />
                    </Link>
                </Grid>
                <BusinessHours supplier={props.supplier} />
                <Grid item xs={12}>
                    <Divider flexItem />
                </Grid>
                <Grid item xs={12}>
                    <Map location={props.supplier.info.location} readonly={true} onLocationChange={() => {}} />
                </Grid>
                <Grid item xs={12}>
                    <Gallery images={props.supplier.images} />
                </Grid>
            </Grid>
        </Grid>
    );
};
