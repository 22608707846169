import axios from 'axios';

export interface QueryOptions {
    limit?: number;
    offset?: number;
    from?: string;
    to?: string;
}

export const setToken = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const invalidateToken = () => {
    axios.defaults.headers.common = {};
};
