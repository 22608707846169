import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    SelectProps,
    SelectChangeEvent,
    FormHelperText,
    useTheme,
} from '@mui/material';

export interface SelectorProps<T = unknown> extends SelectProps<T> {
    label: string;
    value: T;
    options: T[];
    helperText?: string;
    onSelectionChange: (value: T) => void;
    displayOption: (option: T) => string;
}

export const Selector = <T extends unknown>({
    label,
    value,
    options,
    helperText = '',
    onSelectionChange,
    displayOption,
    ...selectProps
}: SelectorProps<T>): JSX.Element => {
    const theme = useTheme();

    return (
        <FormControl fullWidth>
            <InputLabel id="selector-label" error={selectProps.error}>
                {label}
            </InputLabel>
            <Select<T>
                id="selector"
                size="medium"
                fullWidth
                dir={theme.direction}
                label={label}
                value={value ?? ''}
                readOnly={false}
                {...selectProps}
                renderValue={(value) => displayOption(value)}
                onChange={(e: SelectChangeEvent<T>) => onSelectionChange(e.target.value as T)}
            >
                {options.map((option: T, idx: number) => {
                    return (
                        <MenuItem key={idx} value={option as any} dir={theme.direction}>
                            {displayOption(option)}
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText error={selectProps.error} sx={{ fontSize: theme.typography.h6.fontSize }}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};
