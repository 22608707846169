import * as Yup from 'yup';

export type EntityType = {
    _id: string;
    name: string;
};

export const EntityTypeSchema = Yup.object<Record<keyof EntityType, Yup.AnySchema>>({
    _id: Yup.string().required().length(24),
    name: Yup.string().required().max(30),
});
