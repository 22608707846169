import { Grid, Stack } from '@mui/material';
import { SupplierByCategoryQueryOptions } from 'api/suppliers';
import { useGetSuppliersByCategory } from 'api/suppliers-hooks';
import { useLocations } from 'common/hooks/useLocations';
import { Supplier } from 'common/types/supplier';
import { generateTimeInterval } from 'common/utils/date';
import { UpdateLocationButton } from 'components/Buttons';
import { SupplierCard } from 'components/cards/SupplierCard';
import { ListDivider } from 'components/Dividers';
import { Section } from 'components/layout';
import { Selector } from 'components/Selectors';
import { TextCaption, TextHeader } from 'components/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface SearchResultsProps {
    category: string;
    date: Date;
}

export const SearchResults = (props: SearchResultsProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { getLocation, updateLocation } = useLocations();

    const sortOptions = [
        t('views.search.searchResults.sort.distance'),
        t('views.search.searchResults.sort.availability'),
        //t('views.search.searchResults.sort.highestRated'),
    ];

    const [sortByIdx, setSortByIdx] = useState<number>(0);

    const generateQueryParams = (category: string, date: Date, sortByIdx: number): SupplierByCategoryQueryOptions => {
        const coordinates = getLocation();
        const interval = generateTimeInterval(date);
        let sort = 'rating';

        switch (sortByIdx) {
            case 0:
                sort = 'distance';
                break;

            case 1:
                sort = 'availability';
                break;

            case 2:
                sort = 'rating';
                break;
        }

        return { ...interval, category: category, sort: sort, coordinates: coordinates };
    };

    const { data: suppliers } = useGetSuppliersByCategory(generateQueryParams(props.category, props.date, sortByIdx));

    const onSelect = (supplier: Supplier) => {
        navigate('/suppliers/' + supplier._id!, { state: { date: props.date } });
    };

    if (!suppliers) {
        return <></>;
    }

    if (suppliers.length === 0) {
        return (
            <Section>
                <Stack textAlign="center" rowGap={1}>
                    <TextHeader text={t('views.search.searchResults.noResults')} />
                    <TextHeader text={t('views.search.searchResults.criteria')} />
                </Stack>
            </Section>
        );
    }

    return (
        <Stack>
            <Section sx={{ pb: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <TextCaption
                            text={t('views.search.searchResults.availableSuppliers', { count: suppliers.length })}
                        />
                        <UpdateLocationButton
                            label={t('views.search.searchResults.updateLocation')}
                            onClick={updateLocation}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Selector
                            size="small"
                            value={sortOptions[sortByIdx]}
                            label=""
                            options={sortOptions}
                            onSelectionChange={(value) => setSortByIdx(sortOptions.findIndex((item) => value === item))}
                            displayOption={(option) => option}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Stack divider={<ListDivider />}>
                {suppliers.map((supplier, idx) => {
                    return <SupplierCard key={idx} supplier={supplier} onSelect={() => onSelect(supplier)} />;
                })}
            </Stack>
        </Stack>
    );
};
