import { CssBaseline, StyledEngineProvider, createTheme, ThemeOptions, ThemeProvider } from '@mui/material';
import { themePalette } from './palette';
import { themeTypography } from './typography';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { useTranslation } from 'react-i18next';

export const StyleProvider = ({ children }: { children: React.ReactElement }) => {
    const { i18n } = useTranslation();

    const themeOptions: ThemeOptions = {
        direction: i18n.dir(),
        palette: themePalette(),
        typography: themeTypography(),
    };

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    const cacheLtr = createCache({ key: 'muiltr' });

    return (
        <StyledEngineProvider injectFirst>
            <CacheProvider value={themeOptions.direction === 'ltr' ? cacheLtr : cacheRtl}>
                <ThemeProvider theme={createTheme(themeOptions)}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </CacheProvider>
        </StyledEngineProvider>
    );
};
