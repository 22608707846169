import { Avatar, Grid, Stack } from '@mui/material';
import { getTeamMembersThatProvideService } from 'common/utils/supplier';
import ListCard from 'components/cards/ListCards';
import { ListCardTitle } from 'components/Text';
import { useMemo } from 'react';
import { SelectTeamMemberProps } from './ScheduleProps';
import { stringToColor } from '../../common/utils/color';

export const SelectTeamMember = (props: SelectTeamMemberProps) => {
    const teamMembers = useMemo(() => {
        return getTeamMembersThatProvideService(props.supplier, props.serviceLabel);
    }, [props]);

    return (
        <Grid container justifyContent="center" spacing={2}>
            {teamMembers.map((member, idx) => {
                return (
                    <Grid item xs={12} key={idx}>
                        <ListCard
                            element={
                                <Stack direction="row" gap={2} alignItems="center">
                                    <Avatar
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            backgroundColor: stringToColor(member.profile.name),
                                        }}
                                    />
                                    <ListCardTitle text={member.profile.name} />
                                </Stack>
                            }
                            onClick={() => {
                                props.onSelect(member);
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
