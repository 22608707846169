import axios from 'axios';
import { Coordinates } from 'common/types/supplier';

export const getLocationByIp: () => Promise<Coordinates> = async () => {
    const url = `https://ipapi.co/json/`;

    const res = await axios.get(url);
    return { lat: res.data.latitude, lng: res.data.longitude };
};

export const getAccurateLocation: () => Promise<Coordinates> = async () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
        }, reject);
    });
};
