import {
    updateEventStatus,
    createEvent,
    deleteEvent,
    getEvent,
    getEventsBySupplierId,
    getEventsByTeamMemberId,
    getEventsByUserId,
    updateEvent,
} from 'api/events';
import { useNotifications } from 'common/hooks/useNotifications';
import { EventStatus } from 'common/types/calendar';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from 'react-query';

const EVENT_KEY = (eventId: string) => ['events', eventId];
const EVENTS_USER_KEY = (userId: string) => ['events', 'userId', userId];
const EVENTS_OWNER_KEY = (supplierId: string) => ['events', 'supplierId', supplierId];
const EVENTS_TEAM_MEMBER_KEY = (supplierId: string, teamMemberId: string) => [
    'events',
    'teamMemberId',
    supplierId,
    teamMemberId,
];

export const useGetEventsByUserId = (userId: string) => {
    return useQuery(EVENTS_USER_KEY(userId), () => getEventsByUserId(userId), {
        enabled: !!userId,
    });
};

export const useGetEventsBySupplierId = (supplierId: string) => {
    return useQuery(EVENTS_OWNER_KEY(supplierId), () => getEventsBySupplierId(supplierId), {
        enabled: !!supplierId,
    });
};

export const useGetEventsByTeamMemberId = (supplierId: string, teamMemberId: string) => {
    return useQuery(
        EVENTS_TEAM_MEMBER_KEY(supplierId, teamMemberId),
        () => getEventsByTeamMemberId(supplierId, teamMemberId),
        {
            enabled: !!teamMemberId && !!supplierId,
        }
    );
};

export const useGetEvent = (eventId: string) => {
    return useQuery(EVENT_KEY(eventId), () => getEvent(eventId), { enabled: !!eventId });
};

export const useCreateEvent = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(createEvent, {
        onMutate: () => {
            notify(t('notifications.event.create.mutate'));
        },
        onError: () => {
            notify(t('notifications.event.create.error'));
        },
        onSuccess: () => {
            queryClient.invalidateQueries();
            notify(t('notifications.event.create.success'));
        },
    });
};

export const useUpdateEvent = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(updateEvent, {
        onMutate: () => {
            notify(t('notifications.event.update.mutate'));
        },
        onError: () => {
            notify(t('notifications.event.update.error'));
        },
        onSuccess: () => {
            queryClient.invalidateQueries();
            notify(t('notifications.event.update.success'));
        },
    });
};

export const useDeleteEvent = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(deleteEvent, {
        onMutate: () => {
            notify(t('notifications.event.delete.mutate'));
        },
        onError: () => {
            notify(t('notifications.event.delete.error'));
        },
        onSuccess: () => {
            queryClient.invalidateQueries();
            notify(t('notifications.event.delete.success'));
        },
    });
};

export const useUpdateEventStatus = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(updateEventStatus, {
        onMutate: () => {
            notify(t('notifications.event.updateStatus.mutate'));
        },
        onError: () => {
            notify(t('notifications.event.updateStatus.error'));
        },
        onSuccess: (_, params) => {
            queryClient.invalidateQueries();

            switch (params.eventStatus) {
                case EventStatus.Cancelled:
                    notify(t('notifications.event.updateStatus.cancelled'));
                    return;

                case EventStatus.Scheduled:
                    notify(t('notifications.event.updateStatus.scheduled'));
                    return;

                case EventStatus.Verified:
                    notify(t('notifications.event.updateStatus.verified'));
                    return;
            }
        },
    });
};
