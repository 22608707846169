import { Chip, Divider, Grid } from '@mui/material';
import { IconBookmark, IconCalendar, IconClock, IconReceipt } from '@tabler/icons-react';
import { useCreateEvent } from 'api/events-hooks';
import { useAuth } from 'common/hooks/useAuth';
import { useLayout } from 'common/hooks/useLayout';
import { UserProfile } from 'common/types/auth';
import { EventStatus, EventType } from 'common/types/calendar';
import { displayDate, displayTime } from 'common/utils/date';
import { isReservedSlot } from 'common/utils/events';
import { ConfirmMeetingButton } from 'components/Buttons';
import { IconNext, IconWithText } from 'components/Icons';
import { WebOnly } from 'components/layout';
import { DialogHeader } from 'contexts/layout/LayoutContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginDialog } from 'views/auth/Login';
import { ConfirmProps } from './ScheduleProps';

export const Confirm = (props: ConfirmProps) => {
    const { t } = useTranslation();
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useLayout();

    const createEvent = useCreateEvent();
    const [loading, setLoading] = useState<boolean>(false);

    const generateEvent = (user: UserProfile) => {
        createEvent.mutate(
            {
                title: props.service.label,
                description: '',
                start: props.timeslot.start,
                end: props.timeslot.end,
                creator: { _id: user!._id!, name: user!.fullName },
                supplier: { _id: props.supplier._id!, name: props.supplier.info.name },
                teamMember: { _id: props.teamMember.profile._id!, name: props.teamMember.profile.name },
                serviceLabel: props.service.label,
                reservedSlot: isReservedSlot(props.service.label),
                status: EventStatus.Created,
            },
            {
                onSuccess: (event: EventType) => {
                    setLoading(false);
                    closeDialog();

                    navigate('/booking/confirmation/' + event._id!);
                },
            }
        );
    };

    const onClick = async () => {
        setLoading(true);

        if (!isLoggedIn || !user) {
            return openDialog({
                children: (
                    <>
                        <DialogHeader
                            title={t('components.layout.sidebar.account.login')}
                            actions={[]}
                            onClose={() => {
                                setLoading(false);
                                closeDialog();
                            }}
                        />
                        <LoginDialog
                            onSuccess={(user) => {
                                generateEvent(user);
                            }}
                            onError={() => {
                                setLoading(false);
                            }}
                        />
                    </>
                ),
            });
        }

        generateEvent(user);
    };

    return (
        <>
            <Grid container alignItems="center">
                <Grid item xs={12} md={7}>
                    <Grid container rowSpacing={2} justifyContent="space-around">
                        <Grid item xs={11}>
                            <IconWithText
                                icon={IconBookmark}
                                text={t('components.schedule.details.service', {
                                    serviceLabel: props.service.label,
                                    teamMember: props.teamMember.profile.name,
                                })}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <IconWithText icon={IconCalendar} text={displayDate(props.timeslot.start)} />
                        </Grid>
                        <Grid item xs={11}>
                            <IconWithText
                                icon={IconClock}
                                text={t('components.schedule.details.interval', {
                                    start: displayTime(props.timeslot.start),
                                    end: displayTime(props.timeslot.end),
                                })}
                            />
                        </Grid>
                        {props.teamMember.settings.showPrices && props.service.price && (
                            <Grid item xs={11}>
                                <IconWithText
                                    icon={IconReceipt}
                                    text={t('components.schedule.details.price', { price: props.service.price })}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <WebOnly>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }}>
                        <Chip sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} icon={IconNext()} />
                    </Divider>
                </WebOnly>
                <Grid item xs>
                    <ConfirmMeetingButton
                        label={t('components.schedule.details.action')}
                        onClick={onClick}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
};
