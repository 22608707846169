import { IconButton, Snackbar, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import React, { createContext, useState } from 'react';

export type NotificationContextType = {
    notify: (message: string, autoHide?: boolean) => void;
};

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider = ({ children }: { children: React.ReactElement }) => {
    const theme = useTheme();

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [autoHide, setAutoHide] = useState<boolean>(true);

    const notify = (message: string, autoHide = true) => {
        setAutoHide(autoHide);
        setMessage(message);
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setMessage('');
        setAutoHide(true);
    };

    return (
        <NotificationContext.Provider value={{ notify }}>
            {children}
            <Snackbar
                open={open}
                dir={theme.direction}
                autoHideDuration={autoHide ? 6000 : null}
                onClose={handleClose}
                message={message}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <IconX />
                    </IconButton>
                }
            />
        </NotificationContext.Provider>
    );
};
