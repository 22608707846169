const BASE_API = process.env.REACT_APP_BACKEND_URI;

// Supplier APIs
export const SUPPLIERS_API = `${BASE_API}/suppliers`;
export const SUPPLIER_API = (supplierId: string) => `${SUPPLIERS_API}/${supplierId}`;
export const SUPPLIER_TEAM_MEMBERS_API = (supplierId: string) => `${SUPPLIER_API(supplierId)}/team`;
export const SUPPLIER_AVAILABILITY_API = (supplierId: string) => `${SUPPLIER_API(supplierId)}/availability`;
export const SUPPLIERS_SEARCH_API = `${SUPPLIERS_API}/search`;
export const SUPPLIERS_DISTINCT_CATEGORIES_API = `${SUPPLIERS_API}/categories`;

// Events APIs
export const EVENTS_API = `${BASE_API}/events`;
export const EVENTS_USER_API = (id: string) => `${EVENTS_API}/user/${id}`;
export const EVENTS_SUPPLIER_API = (supplierId: string) => `${EVENTS_API}/supplier/${supplierId}`;
export const EVENTS_SUPPLIER_OWNER_API = (supplierId: string) => `${EVENTS_SUPPLIER_API(supplierId)}/owner`;
export const EVENTS_SUPPLIER_TEAM_MEMBER_API = (supplierId: string, teamMemberId: string) =>
    `${EVENTS_SUPPLIER_API(supplierId)}/team/${teamMemberId}`;

export const EVENT_API = (eventId: string) => `${EVENTS_API}/${eventId}`;
export const EVENT_UPDATE_STATUS_API = (eventId: string) => `${EVENT_API(eventId)}/status`;

// User APIs
export const USERS_API = `${BASE_API}/users`;
export const USER_API = (userId: string) => `${USERS_API}/${userId}`;
export const USER_ROLES_API = (userId: string) => `${USERS_API}/${userId}/roles`;

// Auth APIs
export const AUTH_API = `${BASE_API}/auth`;

export const AUTH_GOOGLE_API = `${AUTH_API}/google`;
export const AUTH_GOOGLE_LOGIN_API = `${AUTH_GOOGLE_API}/login`;
export const AUTH_GOOGLE_TOKEN_API = `${AUTH_GOOGLE_API}/token`;

export const AUTH_LOGIN_API = `${AUTH_API}/login`;
export const AUTH_REGISTER_API = `${AUTH_API}/register`;

export const AUTH_LOGOUT_API = `${AUTH_API}/logout`;

// Customers APIs
export const CUSTOMERS_API = (supplierId: string) => `${BASE_API}/customers/${supplierId}`;

// Storage APIs
export const STORAGE_SIGNED_URL = `${BASE_API}/storage/url`;
