import { EventApi } from '@fullcalendar/react';
import { EventType, ReservedServiceLabel } from 'common/types/calendar';
import { DateRange } from 'common/types/calendar';
import { Service } from 'common/types/supplier';
import { TFunction } from 'i18next';
import { toMs } from './date';

export const toEventType = (event: EventApi): EventType => {
    return event.toPlainObject({ collapseExtendedProps: true }) as EventType;
};

export const calculateDuration = (range: DateRange) => {
    return (toMs(range.end) - toMs(range.start)) / 60000;
};

export const filterServices = (range: DateRange, services: Service[]) => {
    const duration = calculateDuration(range);
    return services.filter((item) => item.duration === duration);
};

export const isReservedSlot = (serviceLabel: string) => {
    return Object.keys(ReservedServiceLabel).findIndex((item) => item === serviceLabel) > -1;
};

export const getServiceLabel = (serviceLabel: string, t: TFunction) => {
    const index = Object.keys(ReservedServiceLabel).findIndex((item) => item === serviceLabel);
    if (index > -1) {
        return t(`views.supplier.schedule.calendar.${Object.values(ReservedServiceLabel).at(index)}`);
    }

    return serviceLabel;
};
