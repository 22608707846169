import { Divider } from '@mui/material';
import { MobileOnly } from './layout';

export const ListDivider = () => {
    return (
        <MobileOnly>
            <Divider sx={{ height: '4px', backgroundColor: '#f5f5f5' }} />
        </MobileOnly>
    );
};
