import { SupplierContext } from 'contexts/suppliers/SuppliersContext';
import { useContext } from 'react';

export const useSuppliers = () => {
    const context = useContext(SupplierContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};
