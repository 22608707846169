import { Box } from '@mui/material';
import { Service, ServiceSchema, Supplier, TeamMember, TeamMemberSchema } from 'common/types/supplier';
import { FlowTabs } from 'components/Tabs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SelectService } from 'components/schedule/SelectService';
import { SelectTeamMember } from 'components/schedule/SelectTeamMember';
import { SelectTimeslot } from 'components/schedule/SelectTimeslot';
import { TimeslotType, TimeslotTypeSchema } from 'common/types/calendar';
import { useState } from 'react';
import { Confirm } from 'components/schedule/Confirm';
import { MobileOnly, WebOnly } from 'components/layout';
import { getServiceForTeamMember } from 'common/utils/supplier';
import { useLayout } from 'common/hooks/useLayout';
import { DialogHeader } from 'contexts/layout/LayoutContext';

export type SupplierInfoProps = {
    date: Date;
};

export interface ScheduleProps {
    supplier: Supplier;
}

interface SelectServiceValues {
    serviceLabel: string | null;
    teamMember: TeamMember | null;
    service: Service | null;
    timeslot: TimeslotType | null;
}

const ScheduleFlow = (props: ScheduleProps) => {
    const { t } = useTranslation();

    const state = useLocation().state as SupplierInfoProps;

    const [tabIdx, setTabIdx] = useState(0);

    const formik = useFormik({
        initialValues: {
            serviceLabel: null,
            teamMember: null,
            service: null,
            timeslot: null,
        } as SelectServiceValues,
        validationSchema: Yup.object().shape({
            serviceLabel: Yup.string().required(),
            teamMember: TeamMemberSchema.required(),
            service: ServiceSchema.required(),
            timeslot: TimeslotTypeSchema.required(),
        }),
        onSubmit: async (values) => {},
    });

    return (
        <FlowTabs
            index={tabIdx}
            onSelect={(index) => setTabIdx(index)}
            items={[
                {
                    label: t('views.supplier.home.schedule.service.label'),
                    title: t('views.supplier.home.schedule.service.title'),
                    element: (
                        <SelectService
                            supplier={props.supplier}
                            onSelect={(serviceLabel: string) => {
                                formik.setValues({ ...formik.values, serviceLabel: serviceLabel });
                                setTabIdx(tabIdx + 1);
                            }}
                        />
                    ),
                },
                {
                    label: t('views.supplier.home.schedule.teamMember.label'),
                    title: t('views.supplier.home.schedule.teamMember.title'),
                    element: (
                        <SelectTeamMember
                            supplier={props.supplier}
                            serviceLabel={formik.values.serviceLabel!}
                            onSelect={(teamMember: TeamMember) => {
                                formik.setValues({
                                    ...formik.values,
                                    teamMember: teamMember,
                                    service: getServiceForTeamMember(teamMember, formik.values.serviceLabel!),
                                });
                                setTabIdx(tabIdx + 1);
                            }}
                        />
                    ),
                },
                {
                    label: t('views.supplier.home.schedule.timeslot.label'),
                    title: t('views.supplier.home.schedule.timeslot.title'),
                    element: (
                        <SelectTimeslot
                            supplier={props.supplier}
                            service={formik.values.service!}
                            teamMember={formik.values.teamMember!}
                            date={state ? state.date : new Date()}
                            onSelect={(timeslot: TimeslotType) => {
                                formik.setValues({ ...formik.values, timeslot: timeslot });
                                setTabIdx(tabIdx + 1);
                            }}
                        />
                    ),
                },
                {
                    label: t('views.supplier.home.schedule.details.label'),
                    title: t('views.supplier.home.schedule.details.title'),
                    element: (
                        <Confirm
                            service={formik.values.service!}
                            teamMember={formik.values.teamMember!}
                            supplier={props.supplier}
                            timeslot={formik.values.timeslot!}
                            onSelect={async () => await formik.submitForm()}
                        />
                    ),
                },
            ]}
        />
    );
};

export const WebSchedule = (props: ScheduleProps) => {
    return (
        <WebOnly>
            <Box sx={{ width: '100%', border: 1, borderColor: 'divider', borderRadius: '4px' }}>
                <ScheduleFlow supplier={props.supplier} />
            </Box>
        </WebOnly>
    );
};

export const MobileSchedule = (props: ScheduleProps) => {
    const { t } = useTranslation();
    const { closeDialog } = useLayout();

    return (
        <MobileOnly>
            <DialogHeader
                title={t('components.schedule.title', { supplier: props.supplier.info.name })}
                actions={[]}
                onClose={closeDialog}
            />
            <ScheduleFlow supplier={props.supplier} />
        </MobileOnly>
    );
};
