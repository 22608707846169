import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Divider, TextField, Link, DialogContent } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserCredentials, UserProfile } from 'common/types/auth';
import { useNotifications } from 'common/hooks/useNotifications';
import { ActionButton } from 'components/Buttons';
import { useAuth } from 'common/hooks/useAuth';
import { Section } from 'components/layout';
import GoogleButton from 'react-google-button';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import { UserCredentialsSchema } from '../../common/types/auth';
import { useLayout } from 'common/hooks/useLayout';

interface LoginDialogProps {
    onSuccess: (user: UserProfile) => void;
    onError: () => void;
}
interface LoginFormProps extends LoginDialogProps {
    onRegister: () => void;
}

export const LoginSuccess = () => {
    const [searchParams] = useSearchParams();

    if (window.opener) {
        window.opener.postMessage(
            { state: searchParams.get('state'), code: searchParams.get('code') },
            window.opener.origin
        );

        window.close();
    }

    return <></>;
};

const LoginForm = (props: LoginFormProps) => {
    const { login, loginWithGoogle } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { notify } = useNotifications();

    const theme = useTheme();
    const { isMobile } = useLayout();

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: UserCredentialsSchema,
        onSubmit: (values: UserCredentials) => {
            login(values.email, values.password, {
                onSuccess: (user: UserProfile) => {
                    notify(t('notifications.auth.login.success', { email: user.email }));
                    props.onSuccess(user);
                },
                onError: () => {
                    notify(t('notifications.auth.login.error'));
                    props.onError();
                },
            });
        },
    });

    const onGoogleLogin = () => {
        loginWithGoogle({
            onSuccess: (user: UserProfile) => {
                notify(t('notifications.auth.login.success', { email: user.email }));
                props.onSuccess(user);
            },
            onError: () => {
                notify(t('notifications.auth.login.error'));
                props.onError();
            },
        });
    };

    const onRegister = () => {
        props.onRegister();

        navigate('/auth/register', { replace: false });
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate dir={theme.direction}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                    <Typography color={theme.palette.primary.main} variant={isMobile ? 'h3' : 'h2'}>
                        {t('views.auth.login.title').toString()}
                    </Typography>
                    <Typography
                        sx={{ pb: 2 }}
                        variant="caption"
                        fontSize={theme.typography.body1.fontSize}
                        textAlign="center"
                    >
                        {t('views.auth.login.credentials').toString()}
                        <Link
                            component="button"
                            type="button"
                            variant="caption"
                            onClick={onRegister}
                            sx={{ pb: '1px' }}
                        >
                            {t('views.auth.login.register').toString()}
                        </Link>{' '}
                        {t('views.auth.login.continue').toString()}
                    </Typography>
                    <GoogleButton onClick={onGoogleLogin} style={{ width: '100%', boxShadow: 'none' }} />
                </Stack>
                <Divider sx={{ my: 4 }} />
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                    <TextField
                        fullWidth
                        type="email"
                        autoComplete="username"
                        label={t('views.auth.login.email').toString()}
                        {...formik.getFieldProps('email')}
                        error={Boolean(formik.touched.email && formik.errors.email)}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        label={t('views.auth.login.password').toString()}
                        {...formik.getFieldProps('password')}
                        error={Boolean(formik.touched.password && formik.errors.password)}
                    />
                    <ActionButton label={t('views.auth.login.action')} onClick={formik.handleSubmit} />
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export const LoginPage = () => {
    return (
        <Stack mt={8} sx={{ alignItems: { xs: 'inherit', sm: 'center' } }}>
            <Section>
                <LoginForm onSuccess={(user) => {}} onError={() => {}} onRegister={() => {}} />
            </Section>
        </Stack>
    );
};

export const LoginDialog = (props: LoginDialogProps) => {
    const { isMobile, closeDialog } = useLayout();

    return (
        <DialogContent sx={{ py: 2, px: 2, mt: isMobile ? 8 : 2 }}>
            <LoginForm {...props} onRegister={closeDialog} />
        </DialogContent>
    );
};
