import { Avatar, Box, Drawer, useTheme } from '@mui/material';
import { MenuGroupItemType, MobileSidebar } from 'components/MobileSidebar';
import { useAuth } from 'common/hooks/useAuth';
import { useState, useEffect } from 'react';
import {
    IconCalendar,
    IconCalendarEvent,
    IconLogin,
    IconLogout,
    IconSettings,
    IconListDetails,
    IconLayoutList,
    IconShare,
    IconSelector,
} from '@tabler/icons-react';
import { Supplier } from 'common/types/supplier';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'common/hooks/useNotifications';
import { isSoleOwnerAndTeamMember, isTeamMember, isOwner } from 'common/utils/supplier';
import { useSuppliers } from 'common/hooks/useSuppliers';
import { Icon } from 'components/Icons';

export interface SidebarProps {
    isOpen: boolean;
    handleToggle: () => void;
}

export const Sidebar = (props: SidebarProps) => {
    const { isLoggedIn, user, logout } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const [menuItems, setMenuItems] = useState<MenuGroupItemType[]>([]);

    const { supplier, suppliersList } = useSuppliers();

    useEffect(() => {
        const getSupplierOwnerItems = (supplier: Supplier): MenuGroupItemType => {
            const items = [
                {
                    title: t('components.layout.sidebar.owner.share'),
                    icon: <Icon icon={IconShare} />,
                    execute: async () => {
                        await navigator.clipboard.writeText(`${window.origin}/suppliers/${supplier?._id}`);
                        notify(t('notifications.supplier.share.copy'));
                    },
                },
                {
                    title: t('components.layout.sidebar.owner.settings'),
                    icon: <Icon icon={IconSettings} />,
                    execute: () => navigate('/supplier/owner/settings'),
                },
            ]
                .concat(
                    !isSoleOwnerAndTeamMember(supplier)
                        ? [
                              {
                                  title: t('components.layout.sidebar.owner.teamMembers'),
                                  icon: <Icon icon={IconLayoutList} />,
                                  execute: () => navigate('/supplier/owner/team'),
                              },
                          ]
                        : []
                )
                .concat([
                    {
                        title: t('components.layout.sidebar.owner.customers'),
                        icon: <Icon icon={IconListDetails} />,
                        execute: () => navigate('/supplier/owner/customers'),
                    },
                    {
                        title: t('components.layout.sidebar.owner.schedule'),
                        icon: <Icon icon={IconCalendar} />,
                        execute: () => navigate('/supplier/owner/schedule'),
                    },
                ]);

            return {
                title: `${supplier.info.name} - ${t('components.layout.sidebar.owner.title')}`,
                children: items,
            };
        };

        const getSupplierTeamMemberItems = (supplier: Supplier): MenuGroupItemType => {
            return {
                title: `${supplier.info.name} - ${t('components.layout.sidebar.teamMember.title')}`,
                children: [
                    {
                        title: t('components.layout.sidebar.teamMember.settings'),
                        icon: <Icon icon={IconSettings} />,
                        execute: () => navigate('/supplier/team/settings'),
                    },
                    {
                        title: t('components.layout.sidebar.teamMember.schedule'),
                        icon: <Icon icon={IconCalendar} />,
                        execute: () => navigate('/supplier/team/schedule'),
                    },
                ],
            };
        };

        const loggedInItems: MenuGroupItemType = {
            title: t('components.layout.sidebar.account.title'),
            children: [
                {
                    title: t('components.layout.sidebar.account.profile'),
                    icon: <Avatar src={user?.picture} sx={{ height: '24px', width: '24px' }} />,
                    execute: () => navigate('/users/profile'),
                },
                {
                    title: t('components.layout.sidebar.account.events'),
                    icon: <Icon icon={IconCalendarEvent} />,
                    execute: () => navigate('/consumer/events'),
                },
                {
                    title: t('components.layout.sidebar.account.logout'),
                    icon: <Icon icon={IconLogout} />,
                    execute: () => {
                        logout({
                            onSuccess: () => {
                                notify(t('notifications.auth.logout.success'));
                                navigate('/');
                            },
                        });
                    },
                },
            ],
        };

        const loggedOutItems: MenuGroupItemType = {
            title: t('components.layout.sidebar.account.title'),
            children: [
                {
                    title: t('components.layout.sidebar.account.login'),
                    icon: <Icon icon={IconLogin} />,
                    execute: () => navigate('/auth/login'),
                },
            ],
        };

        const selectSupplier: MenuGroupItemType = {
            title: t('components.layout.sidebar.selectSupplier.title'),
            children: [
                {
                    title: t('components.layout.sidebar.selectSupplier.select'),
                    icon: <Icon icon={IconSelector} />,
                    execute: () => navigate('/supplier/select'),
                },
            ],
        };

        const generateMenuItems = async () => {
            const items = [];

            if (isLoggedIn) {
                items.push(loggedInItems);

                if (supplier) {
                    if (suppliersList.length > 1) {
                        items.push(selectSupplier);
                    }

                    if (isTeamMember(user?._id!, supplier)) {
                        items.push(getSupplierTeamMemberItems(supplier));
                    }

                    if (isOwner(user?._id!, supplier)) {
                        items.push(getSupplierOwnerItems(supplier));
                    }
                }
            } else {
                items.push(loggedOutItems);
            }

            setMenuItems(items);
        };

        generateMenuItems();
    }, [isLoggedIn, user, logout, supplier, suppliersList, t, navigate, notify]);

    return (
        <Box component="nav" sx={{ flexShrink: { sm: 0 }, width: 260 }} aria-label="mailbox folders">
            <Drawer
                variant="temporary"
                anchor="left"
                open={props.isOpen}
                onClose={props.handleToggle}
                sx={{ '& .MuiDrawer-paper': { width: 260 } }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
                dir={theme.direction}
            >
                <Box sx={{ px: 2 }}>
                    <MobileSidebar items={menuItems} onSelect={props.handleToggle} />
                </Box>
            </Drawer>
        </Box>
    );
};
