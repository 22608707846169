import { TimeslotType } from 'common/types/calendar';
import { AvailabliltyInterval } from 'common/types/supplier';
import { addMinutes, areIntervalsOverlapping, eachMinuteOfInterval, isAfter, isBefore, max, min } from 'date-fns';
import { getDayIdx } from './date';

export const sortTimeslots = (timeslots: TimeslotType[]) => {
    timeslots.sort((a: TimeslotType, b: TimeslotType) => {
        const startA = new Date(a.start);
        const startB = new Date(b.start);

        if (!a.start || !a.end) {
            return 1;
        }

        if (!b.start || !b.end) {
            return -1;
        }

        if (startA.getDay() !== startB.getDay()) {
            return startA.getDay() - startB.getDay();
        }

        if (startA.getHours() !== startB.getHours()) {
            return startA.getHours() - startB.getHours();
        }

        return startA.getMinutes() - startB.getMinutes();
    });

    return timeslots;
};

const skipTimeslot = (value: TimeslotType) => {
    if (!value.start || !value.end) {
        return true;
    }

    if (value.start >= value.end) {
        return true;
    }

    return false;
};

export const isValidTimeslot = (value: TimeslotType, timeslots: TimeslotType[]) => {
    if (skipTimeslot(value)) {
        return true;
    }

    return timeslots.every((item) => {
        if (skipTimeslot(item)) {
            return true;
        }

        if (value === item) {
            return true;
        }

        return !areIntervalsOverlapping(parseTimeslot(value), parseTimeslot(item));
    });
};

const parseTimeslot = (timeslot: TimeslotType) => {
    return { start: new Date(timeslot.start), end: new Date(timeslot.end) };
};

export const generateIntervalSlots = (
    interval: TimeslotType,
    seats: number,
    duration: number,
    step: number
): AvailabliltyInterval[] => {
    const end = new Date(interval.end);
    const start = new Date(interval.start);

    if (isBefore(start, end)) {
        return eachMinuteOfInterval({ start: start, end: end }, { step: step })
            .map((date) => {
                return { interval: { start: date, end: addMinutes(date, duration) }, seats: seats };
            })
            .filter((value) => !isAfter(value.interval.end, end));
    }

    return [];
};

export const consolidateTimeslots = (a: TimeslotType[], b: TimeslotType[]) => {
    const res = a;
    let isOverlapping = false;

    b.forEach((itemB) => {
        isOverlapping = false;

        res.forEach((itemRes) => {
            if (
                areIntervalsOverlapping(
                    { start: new Date(itemRes.start), end: new Date(itemRes.end) },
                    { start: new Date(itemB.start), end: new Date(itemB.end) }
                )
            ) {
                if (itemRes.start !== itemB.start) {
                    itemRes.start = min([itemRes.start, itemB.start]);
                    isOverlapping = true;
                }

                if (itemRes.end !== itemB.end) {
                    itemRes.end = max([itemRes.end, itemB.end]);
                    isOverlapping = true;
                }
            }
        });

        if (!isOverlapping) {
            res.push(itemB);
        }
    });

    return res;
};

export const groupTimeslotsByDay = (timeslots: TimeslotType[]) => {
    const res: any = Array.from({ length: 7 }, () => []);

    timeslots.forEach((item) => res[getDayIdx(item.start)].push(item));
    return res;
};
