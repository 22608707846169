import {
    AppBar,
    Dialog,
    DialogActions,
    DialogProps,
    DialogTitle,
    Grid,
    IconButton,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { Icon, IconWithTooltip, IconWithTooltipProps } from 'components/Icons';
import { createContext, ReactElement, useState } from 'react';

export type LayoutContextType = {
    isMobile: boolean;
    openDialog: (props: Partial<DialogProps>) => void;
    closeDialog: () => void;
};

interface DialogHeaderProps {
    title: string;
    actions: IconWithTooltipProps[];
    onClose: () => void;
}

const DialogContainer = (props: DialogProps) => {
    const theme = useTheme();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen={props.fullScreen} dir={theme.direction}>
            {props.children}
        </Dialog>
    );
};

export const DialogHeader = (props: DialogHeaderProps) => {
    const theme = useTheme();

    return (
        <AppBar sx={{ height: 55 }} position="static" color="inherit" elevation={0}>
            <Toolbar
                sx={{ borderBottom: 1, borderColor: theme.palette.text.disabled }}
                style={{ minHeight: 55, paddingRight: 8, paddingLeft: 8 }}
            >
                <IconButton color="inherit" onClick={props.onClose} aria-label="close">
                    <Icon icon={IconX} />
                </IconButton>
                <Grid container alignItems="center" justifyContent="space-between">
                    <DialogTitle variant="h5">{props.title}</DialogTitle>
                    <DialogActions>
                        {props.actions.map((actionProps, idx) => {
                            return <IconWithTooltip key={idx} {...actionProps} />;
                        })}
                    </DialogActions>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export const LayoutContext = createContext<LayoutContextType | null>(null);

export const LayoutProvider = ({ children }: { children: ReactElement }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [dialogs, setDialogs] = useState<DialogProps[]>([]);

    const openDialog = (props: Partial<DialogProps>) => {
        setDialogs((dialogs) => [...dialogs, { ...props, open: true, fullScreen: isMobile, onClose: closeDialog }]);
    };

    const closeDialog = () => {
        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();

            if (!latestDialog) {
                return dialogs;
            }

            if (latestDialog.onClose) {
                latestDialog.onClose({}, 'backdropClick');
            }

            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    return (
        <LayoutContext.Provider value={{ isMobile, openDialog, closeDialog }}>
            {children}
            {dialogs.map((dialog, i) => {
                return <DialogContainer key={i} {...dialog} />;
            })}
        </LayoutContext.Provider>
    );
};
