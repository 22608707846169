import { IconButton, Box, MobileStepper } from '@mui/material';
import { useState } from 'react';
import { IconNext, IconPrev } from './Icons';
import { Image } from './Image';

export interface PhotosListProps {
    photos: string[];
}

export const PhotosList = (props: PhotosListProps) => {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = props.photos.length;

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    if (props.photos.length === 0) {
        return <></>;
    }

    return (
        <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '0 0 4px 4px' }}>
            <Image sx={{ height: 275 }} src={props.photos[activeStep]} alt={props.photos[activeStep]} />
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{ borderRadius: 'inherit' }}
                nextButton={
                    <IconButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {IconNext()}
                    </IconButton>
                }
                backButton={
                    <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {IconPrev()}
                    </IconButton>
                }
            />
        </Box>
    );
};
