import { UserRoles } from 'common/types/auth';
import { Supplier, TeamMember } from 'common/types/supplier';

export const getSupplierForOwner = (userRoles: UserRoles | undefined, index: number) => {
    if (!userRoles || userRoles.owner.length <= index) {
        return null;
    }

    return userRoles.owner[index];
};

export const getSupplierForTeamMember = (userRoles: UserRoles | undefined, index: number) => {
    if (!userRoles || userRoles.teamMember.length <= index) {
        return null;
    }

    return userRoles.teamMember[index];
};

export const isTeamMember = (userId: string, supplier: Supplier) => {
    return supplier.team.some((member) => member.profile._id === userId);
};

export const isOwner = (userId: string, supplier: Supplier) => {
    return supplier.owner.some((value) => value._id === userId);
};

export const isTeamMemberAndOwner = (userId: string, supplier: Supplier) => {
    return isOwner(userId, supplier) && isTeamMember(userId, supplier);
};

export const isSingleTeamMember = (supplier: Supplier | null | undefined) => {
    return supplier?.team.length === 1;
};

export const isSingleOwner = (supplier: Supplier | null) => {
    return supplier?.team.length === 1;
};

export const isSoleOwnerAndTeamMember = (supplier: Supplier) => {
    return (
        isSingleOwner(supplier) && isSingleTeamMember(supplier) && isTeamMemberAndOwner(supplier.owner[0]._id, supplier)
    );
};

export const getIndexForTeamMember = (supplier: Supplier | null, teamMemberId: string) => {
    return supplier?.team.findIndex((member) => member.profile._id === teamMemberId);
};

export const getTeamMemberByIndex = (supplier: Supplier | null | undefined, index: number) => {
    if (!supplier || supplier.team.length < index) {
        return null;
    }

    return supplier.team[index];
};

export const getServiceLabels = (teamMembers: TeamMember[]): string[] => {
    const res = new Set<string>();
    teamMembers.forEach((member) => member.services.forEach((service) => res.add(service.label)));

    return Array.from(res);
};

export const getServiceForTeamMember = (teamMember: TeamMember, serviceLabel: string) => {
    return teamMember.services.find((service) => service.label === serviceLabel) ?? null;
};

export const getTeamMembersThatProvideService = (supplier: Supplier, serviceLabel: string): TeamMember[] => {
    return supplier.team.filter((member) => getServiceForTeamMember(member, serviceLabel) !== undefined);
};
