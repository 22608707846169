import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { Location } from 'common/types/supplier';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const libraries: 'places'[] = ['places'];

const containerStyle = {
    width: '100%',
    height: 275,
};

const enum GoogleAddressType {
    Address = 'street_address',
    StreetNumber = 'street_number',
    Street = 'route',
    Locality = 'locality',
    Region = 'administrative_area_level_1',
    Country = 'country',
}

export interface MapProps {
    location: Location;
    readonly: boolean;
    onLocationChange: (location: Location) => void;
}

function Map(props: MapProps) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
        libraries: libraries,
    });

    const { t } = useTranslation();
    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

    const parseResults = useCallback(
        (results: (google.maps.GeocoderResult | google.maps.places.PlaceResult)[]): Location | null => {
            const parseProperty = (
                address: google.maps.GeocoderResult | google.maps.places.PlaceResult,
                type: GoogleAddressType,
                short: boolean
            ) => {
                const property = address.address_components?.find((item) =>
                    item.types.find((itemType) => itemType === type)
                );

                if (!property) {
                    return '';
                }

                return short ? property.short_name : property.long_name;
            };

            let address = results.find((item) => {
                if (!item.types) {
                    return null;
                }

                return item.types.findIndex((type) => type === GoogleAddressType.Address) > -1;
            });

            if (!address) {
                address = results.find((item) => {
                    if (!item.types) {
                        return null;
                    }

                    return item.types.findIndex((type) => type === GoogleAddressType.Street) > -1;
                });

                if (!address) {
                    return null;
                }
            }

            if (!address.geometry?.location) {
                return null;
            }

            return {
                coordinates: { lat: address.geometry.location.lat(), lng: address.geometry.location.lng() },
                address: {
                    street:
                        parseProperty(address, GoogleAddressType.Street, true) +
                        ' ' +
                        parseProperty(address, GoogleAddressType.StreetNumber, true),
                    locality: parseProperty(address, GoogleAddressType.Locality, false),
                    region: parseProperty(address, GoogleAddressType.Region, false),
                    country: parseProperty(address, GoogleAddressType.Country, false),
                },
            };
        },
        []
    );

    const placeMarker = useCallback(
        async (coordinates: google.maps.LatLngLiteral) => {
            const geocoder = new google.maps.Geocoder();
            const res = await geocoder.geocode({ location: coordinates });

            const location = parseResults(res.results);
            if (location) {
                props.onLocationChange(location);
            }
        },
        [props, parseResults]
    );

    const onClick = (e: google.maps.MapMouseEvent) => {
        if (props.readonly) {
            return;
        }

        placeMarker(e.latLng?.toJSON()!);
    };

    if (!isLoaded) {
        return <></>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={props.location.coordinates}
            zoom={15}
            onClick={onClick}
            options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                clickableIcons: false,
                gestureHandling: props.readonly ? 'none' : 'auto',
            }}
        >
            {!props.readonly && (
                <StandaloneSearchBox
                    onLoad={(searchBox) => setSearchBox(searchBox)}
                    onPlacesChanged={() => {
                        const places = searchBox?.getPlaces();

                        if (places) {
                            const location = parseResults(places);
                            if (location) {
                                props.onLocationChange(location);
                            }
                        }
                    }}
                >
                    <input
                        type="search"
                        placeholder={t('components.map.label')}
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            height: `36px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `16px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            left: '10px',
                        }}
                    />
                </StandaloneSearchBox>
            )}
            <Marker position={props.location.coordinates} onLoad={() => {}} />
        </GoogleMap>
    );
}

export default memo(Map);
