import { TypographyOptions } from '@mui/material/styles/createTypography';

export const themeTypography = (): TypographyOptions => {
    return {
        fontFamily: `'Rubik', sans-serif`,
        fontSize: 16,
        h6: {
            fontSize: '0.85rem',
            fontWeight: 400,
        },
        h5: {
            fontSize: '1rem',
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        h1: {
            fontSize: '2rem',
        },
        subtitle1: {
            fontSize: '1rem',
        },
        subtitle2: {
            fontSize: '1rem',
        },
        caption: {
            fontSize: '1rem',
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.85rem',
        },
        button: {
            fontSize: '16px',
            textTransform: 'none',
        },
    };
};
