import { PaletteOptions } from '@mui/material';

export const themePalette = (): PaletteOptions => {
    return {
        mode: 'light',
        primary: {
            main: '#4D73D4',
            light: '#E3F2FD',
            dark: '#1E88E5',
        },
        secondary: {
            main: '#673AB7',
            light: '#EDE7F6',
            dark: '#5E35B1',
        },
        text: {
            primary: '#000000DE',
        },
    };
};
