import { Box, SxProps, Theme } from '@mui/material';

export interface ImageProps {
    sx: SxProps<Theme>;
    src: string;
    alt: string;
}

export const Image = (props: ImageProps) => {
    return (
        <Box sx={props.sx}>
            <img
                src={props.src}
                alt={props.alt}
                loading="lazy"
                width="100%"
                height="100%"
                style={{ objectFit: 'cover', borderRadius: 'inherit' }}
            />
        </Box>
    );
};
