import { AuthGuard } from 'components/layout/Guards/AuthGuard';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from 'components/layout';
import { lazy } from 'react';

const CreateAccount = lazy(() => import('views/supplier/create'));
const SupplierSettings = lazy(() => import('views/supplier/settings/SupplierSettings'));
const SupplierOwnerCalendar = lazy(() => import('views/supplier/schedule/calendar/Owner'));
const SupplierTeamMemberCalendar = lazy(() => import('views/supplier/schedule/calendar/TeamMember'));
const TeamMemberSettings = lazy(() => import('views/supplier/settings/TeamMemberSettings'));
const SuppliersList = lazy(() => import('views/supplier/select'));
const Customers = lazy(() => import('views/supplier/management/Customers'));
const Team = lazy(() => import('views/supplier/management/Team'));

export const SupplierRoutes: RouteObject = {
    path: 'supplier',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'select',
            element: <SuppliersList />,
        },
        {
            path: 'owner/create',
            element: <CreateAccount />,
        },
        {
            path: 'owner/settings',
            element: <SupplierSettings />,
        },
        {
            path: 'owner/customers',
            element: <Customers />,
        },
        {
            path: 'owner/team',
            element: <Team />,
        },
        {
            path: 'owner/schedule',
            element: <SupplierOwnerCalendar />,
        },
        {
            path: 'team/settings',
            element: <TeamMemberSettings />,
        },
        {
            path: 'team/schedule',
            element: <SupplierTeamMemberCalendar />,
        },
    ],
};
