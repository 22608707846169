import { Location } from 'common/types/supplier';

export const isFacebookApp = () => {
    return navigator.userAgent.indexOf('FBAN') > -1 || navigator.userAgent.indexOf('FBAV') > -1;
};

export const phoneLink = (phone: string) => {
    return 'tel:' + phone;
};

export const mailLink = (mail: string) => {
    return 'mailto:' + mail;
};

export const whatsappLink = (phone: string, text: string) => {
    return `whatsapp://send?phone=${phone?.replace('+', '')?.replace(/\s+/g, '') ?? ''}&text=${text}`;
};

export const smsLink = (phone: string) => {
    return 'sms:' + phone;
};

export const wazeLink = (location: Location) => {
    return `https://www.waze.com/ul?ll=${location.coordinates.lat},${location.coordinates.lng}&navigate=yes`;
};
