import { useNotifications } from 'common/hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createUser, deleteUser, getUserProfile, getUserRoles, updateUser } from './users';

const USER_KEY = (userId: string) => ['user', userId];
const USER_ROLES_KEY = (userId: string) => ['roles', userId];

export const useGetUserRoles = (userId: string) => {
    return useQuery(USER_ROLES_KEY(userId), () => getUserRoles(userId), { enabled: !!userId });
};

export const useGetUserProfile = (userId: string) => {
    return useQuery(USER_KEY(userId), () => getUserProfile(userId), { enabled: !!userId });
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();
    return useMutation(createUser, {
        onSuccess: (user) => {
            queryClient.setQueryData(USER_KEY(user._id!), user);
        },
    });
};

export const useUpdateUser = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(updateUser, {
        onError: () => {
            notify(t('notifications.user.update.error'));
        },
        onSuccess: (user) => {
            queryClient.setQueryData(USER_KEY(user._id!), user);

            notify(t('notifications.user.update.success'));
        },
    });
};

export const useDeleteUser = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};
