import axios from 'axios';
import { SignedUrl } from 'common/types/supplier';
import { STORAGE_SIGNED_URL } from './routes';

export interface SignedUrlParams {
    folderName?: string;
    fileName: string;
}

export interface FileUploadParams {
    inputUrl: string;
    outputUrl: string;
}

export const getSignedUrl: (params: SignedUrlParams) => Promise<SignedUrl> = async (params) => {
    const res = await axios.post(STORAGE_SIGNED_URL, params);
    return res.data;
};

export const uploadFile: (params: FileUploadParams) => Promise<void> = async (params) => {
    const file = await fetch(params.inputUrl)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], 'input', { type: blobFile.type }));

    const res = await axios.put(params.outputUrl, file, {
        transformRequest: [
            (data, headers) => {
                delete headers.common;
                return data;
            },
        ],
    });

    return res.data;
};
