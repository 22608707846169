import { Link, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { IconWorld } from '@tabler/icons-react';
import { getCurrentLanguage, switchLanguage } from 'common/utils/locale';

export interface LanguageSelectionProps {}

export const LanguageSelection = (props: LanguageSelectionProps) => {
    const theme = useTheme();

    return (
        <Link
            component="button"
            variant="body2"
            onClick={switchLanguage}
            alignItems="center"
            color="inherit"
            underline="hover"
        >
            <Box display="flex" flexWrap="wrap" alignItems="center">
                <IconWorld stroke={1.2} height="14px" width="14px" />
                <Typography
                    variant="body2"
                    style={{
                        whiteSpace: 'pre-line',
                        direction: 'ltr',
                        textAlign: theme.direction === 'ltr' ? 'left' : 'end',
                    }}
                    sx={{ pl: 1, flex: 1 }}
                >
                    {getCurrentLanguage()}
                </Typography>
            </Box>
        </Link>
    );
};
