import { Grid, useTheme } from '@mui/material';
import { ActionButton } from 'components/Buttons';
import { Selector } from 'components/Selectors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SupplierSearchParams } from 'views/search';
import { Section } from 'components/layout';
import { useGetDistinctCategories } from 'api/suppliers-hooks';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/DatePicker';
import { useLayout } from 'common/hooks/useLayout';

export interface SelectSupplierMinifiedProps {
    date: Date;
    category: string;
    onSubmit: () => void;
}

export const SelectSupplierMinified = (props: SelectSupplierMinifiedProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const theme = useTheme();
    const { isMobile } = useLayout();

    const { data: categories } = useGetDistinctCategories();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { category: props.category, date: props.date },
        validationSchema: Yup.object().shape({
            category: Yup.string().required(),
            date: Yup.date().required(),
        }),
        onSubmit: async (values) => {
            if (!values.category || !values.date) {
                return;
            }

            searchParams.set(SupplierSearchParams.CATEGORY, values.category);
            searchParams.set(SupplierSearchParams.DATE, values.date.toJSON());

            navigate({ pathname: '/search', search: searchParams.toString() });
            props.onSubmit();
        },
    });

    const onClick = async () => {
        await formik.submitForm();
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate dir={theme.direction}>
                <Section>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={7} sm={5}>
                            <Selector
                                value={formik.values.category}
                                label={t('components.selectors.category.label')}
                                options={categories ?? [props.category]}
                                onSelectionChange={async (value) =>
                                    await formik.setValues({ ...formik.values, category: value })
                                }
                                displayOption={(option: any) => t(`components.selectors.category.options.${option}`)}
                                error={Boolean(formik.touched.category && formik.errors.category)}
                            />
                        </Grid>
                        <Grid item xs={5} sm={4}>
                            <DatePicker
                                value={formik.values.date}
                                onChange={async (value) => await formik.setValues({ ...formik.values, date: value! })}
                                minified={isMobile}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <ActionButton label={t('views.search.selectSupplierMinified.action')} onClick={onClick} />
                        </Grid>
                    </Grid>
                </Section>
            </Form>
        </FormikProvider>
    );
};
