import {
    createSupplier,
    deleteSupplier,
    getAvailableTimeslotsForSupplier,
    getDistinctCategories,
    getSupplier,
    getSuppliersByCategory,
    getSupplierTeamMembers,
    searchSuppliers,
    SupplierAvailabilityOptions,
    SupplierByCategoryQueryOptions,
    SupplierSearchOptions,
    updateSupplier,
} from 'api/suppliers';
import { useNotifications } from 'common/hooks/useNotifications';
import { Supplier } from 'common/types/supplier';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSignedUrl, uploadFile } from './files';

const DISTINCT_CATEGORIES_KEY = 'categories';
const SUPPLIER_BY_ID_KEY = (supplierId: string) => ['supplier', 'supplierId', supplierId];
const SUPPLIER_TEAM_MEMBERS_KEY = (supplierId: string) => ['teamMembers', 'supplierId', supplierId];
const SUPPLIERS_BY_CATEGORY_KEY = (opts: SupplierByCategoryQueryOptions) => ['suppliers', ...Object.values(opts)];
const SEARCH_KEY = (opts: SupplierSearchOptions) => ['search', ...Object.values(opts)];
const SUPPLIER_AVAILABILITY_KEY = (supplierId: string, opts: SupplierAvailabilityOptions) => [
    'supplier',
    supplierId,
    ...Object.values(opts),
];

// Supplier APIs
export const useGetSuppliersByCategory = (opts: SupplierByCategoryQueryOptions) => {
    return useQuery(SUPPLIERS_BY_CATEGORY_KEY(opts), () => getSuppliersByCategory(opts), {
        enabled: !!opts.category && !!opts.start && !!opts.end && !!opts.sort && !!opts.coordinates,
        keepPreviousData: true,
    });
};

export const useGetSupplier = (supplierId: string) => {
    return useQuery(SUPPLIER_BY_ID_KEY(supplierId), () => getSupplier(supplierId), { enabled: !!supplierId });
};

export const useGetSupplierTeamMembers = (supplierId: string) => {
    return useQuery(SUPPLIER_TEAM_MEMBERS_KEY(supplierId), () => getSupplierTeamMembers(supplierId), {
        enabled: !!supplierId,
    });
};

export const useCreateSupplier = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(
        async (supplier: Supplier) => {
            notify(t('notifications.supplier.create.inProgress'), false);

            const url = await getSignedUrl({ folderName: undefined, fileName: 'cover' });
            await uploadFile({ inputUrl: supplier.images.profile, outputUrl: url.signedUrl });
            supplier.images.profile = url.publicUrl;

            supplier._id = url.folderName;
            await createSupplier(supplier);

            for (let idx = 0; idx < supplier.images.photos.length; idx = idx + 1) {
                const url = await getSignedUrl({ folderName: supplier._id, fileName: '' + idx });
                await uploadFile({ inputUrl: supplier.images.photos[idx], outputUrl: url.signedUrl });
                supplier.images.photos[idx] = url.publicUrl;
            }

            return await updateSupplier(supplier);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries();
                notify(t('notifications.supplier.create.success'));
            },
            onError: async (error, supplier) => {
                if (supplier._id) {
                    await deleteSupplier(supplier._id);
                }

                notify(t('notifications.supplier.create.error'));
            },
        }
    );
};

export const useUpdateSupplier = () => {
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(
        async (supplier: Supplier) => {
            notify(t('notifications.supplier.update.inProgress'), false);

            if (supplier.images.profile.startsWith('blob:')) {
                const url = await getSignedUrl({ folderName: supplier._id, fileName: 'cover' });
                await uploadFile({ inputUrl: supplier.images.profile, outputUrl: url.signedUrl });
                supplier.images.profile = url.publicUrl;
            }

            for (let idx = 0; idx < supplier.images.photos.length; idx = idx + 1) {
                if (supplier.images.photos[idx].startsWith('blob:')) {
                    const url = await getSignedUrl({ folderName: supplier._id, fileName: '' + idx });
                    await uploadFile({ inputUrl: supplier.images.photos[idx], outputUrl: url.signedUrl });
                    supplier.images.photos[idx] = url.publicUrl;
                }
            }

            return updateSupplier(supplier);
        },
        {
            onMutate: async (supplier) => {
                await queryClient.cancelQueries();

                const previousData = queryClient.getQueryData(SUPPLIER_BY_ID_KEY(supplier._id!));

                queryClient.setQueryData(SUPPLIER_BY_ID_KEY(supplier._id!), supplier);

                return { previousData };
            },
            onError: (_err, supplier, context) => {
                queryClient.setQueryData(SUPPLIER_BY_ID_KEY(supplier._id!), context?.previousData);
                notify(t('notifications.supplier.update.error'));
            },
            onSettled: () => {
                queryClient.invalidateQueries();
            },
            onSuccess: () => {
                notify(t('notifications.supplier.update.success'));
            },
        }
    );
};

export const useDeleteSupplier = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteSupplier, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            queryClient.invalidateQueries();
        },
    });
};

export const useGetAvailableTimeslotsForSupplier = (supplierId: string, opts: SupplierAvailabilityOptions) => {
    return useQuery(
        SUPPLIER_AVAILABILITY_KEY(supplierId, opts),
        () => getAvailableTimeslotsForSupplier(supplierId, opts),
        { enabled: !!supplierId && !!opts.start && !!opts.end && !!opts.teamMemberId && !!opts.serviceLabel }
    );
};

export const useSearchSuppliers = (opts: SupplierSearchOptions) => {
    return useQuery(SEARCH_KEY(opts), () => searchSuppliers(opts), {
        enabled: opts.query.length > 1,
        initialData: [],
        keepPreviousData: opts.query.length > 1,
    });
};

export const useGetDistinctCategories = () => {
    return useQuery(DISTINCT_CATEGORIES_KEY, getDistinctCategories);
};
