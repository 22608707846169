import { Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectSupplierMinified } from 'views/search/SelectSupplierMinified';
import { SearchResults } from './SearchResults';

export enum SupplierSearchParams {
    CATEGORY = 'category',
    DATE = 'date',
}

export const Search = () => {
    const [searchParams] = useSearchParams();

    const initDate = (date: string | null) => {
        return date ? new Date(date) : new Date();
    };

    const initCategory = (category: string | null) => {
        return category ?? '';
    };

    const [date, setDate] = useState<Date>(initDate(searchParams.get(SupplierSearchParams.DATE)));
    const [category, setCategory] = useState<string>(initCategory(searchParams.get(SupplierSearchParams.CATEGORY)));

    useEffect(() => {
        const date = initDate(searchParams.get(SupplierSearchParams.DATE));
        const category = initCategory(searchParams.get(SupplierSearchParams.CATEGORY));

        setDate(date);
        setCategory(category);
    }, [searchParams]);

    return (
        <Stack divider={<Divider />}>
            <SelectSupplierMinified date={date} category={category} onSubmit={() => {}} />
            <SearchResults date={date} category={category} />
        </Stack>
    );
};
