import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Logo } from './layout/Header/Logo';

export type MenuItemType = {
    icon: React.ReactElement;
    execute: () => void;
    title: string;
};

export type MenuGroupItemType = {
    title: string;
    children: MenuItemType[];
};

export interface MenuListProps {
    items: MenuGroupItemType[];
    onSelect: () => void;
}

interface MenuGroupProps {
    item: MenuGroupItemType;
    onSelect: () => void;
}

interface MenuItemProps {
    item: MenuItemType;
    onSelect: () => void;
}

export const MobileSidebar = (props: MenuListProps) => {
    return (
        <Stack justifyContent="center">
            <Logo />
            <Divider />
            {props.items.map((item, idx) => {
                return <MenuGroup item={item} key={idx} onSelect={props.onSelect} />;
            })}
        </Stack>
    );
};

const MenuGroup = (props: MenuGroupProps) => {
    return (
        <>
            <Typography variant="body2" color="primary" sx={{ pt: 1 }}>
                {props.item.title}
            </Typography>
            <List>
                {props.item.children.map((item: MenuItemType, idx: number) => {
                    return <MenuItem key={idx} item={item} onSelect={props.onSelect} />;
                })}
            </List>
            <Divider />
        </>
    );
};

const MenuItem = (props: MenuItemProps) => {
    const handleClick = () => {
        props.item.execute();
        props.onSelect();
    };

    return (
        <ListItemButton onClick={() => handleClick()}>
            <ListItemIcon sx={{ my: 'auto', minWidth: 36, color: 'inherit' }}>{props.item.icon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h5" color="inherit">
                        {props.item.title}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};
